import {
  deleteCreation,
  getAllCreations,
  createCreation,
  getSharedCreation,
  duplicateCreation,
  unsubscribe,
} from '@/domains/creations/creationEndpoint'
import { searchCreation, undoAdd, undoDelete, updateCreation, updateCreationTitle } from './creationEndpoint'

const state = {
  creation: '',
  creations: [],
  sharedCreations: [],
  viewMyCreations: false,
  sidebarOpen: false,
  sidebarInviteOpen: false,
  sidebarType: 'default',
  selectedCreations: [],
  loadingDocument: false,
  pages: [],
  selectedPages: [],
  selectedSharedCreationRole: 'none',
  creationId: '',
  updateCreationType: '',
  creationEditedSuccesfully: false,
  showDeleteModal: false,
  creationsToDelete: [],
  addedPagesCount: 0,
  isLoadingCreationOverview: false,
  duplicatedCreation: false,
  duplicateCreationState: {},
  newCreationDuplication: false,
  // filter: 'all',
  spine: {},
}

const getters = {
  getSpine: (state) => {
    return state.spine
  },
  getFilter: (state) => {
    return state.filter
  },
  getDuplicateCreationState: (state) => {
    return state.duplicateCreationState
  },
  getDuplicatedCreation: (state) => {
    return state.duplicatedCreation
  },
  getCreation: (state) => {
    return state.creation
  },
  getSharedCreations: (state) => {
    return state.sharedCreations
  },
  getViewMyCreations: (state) => {
    return state.viewMyCreations
  },
  getSidebarOpen: (state) => {
    return state.sidebarOpen
  },
  getSelectedCreations: (state) => {
    return state.selectedCreations
  },
  getCreations: (state) => {
    return state.creations
  },
  getSidebarType: (state) => {
    return state.sidebarType
  },
  getsidebarInviteOpen: (state) => {
    return state.sidebarInviteOpen
  },
  getLoadingDocument: (state) => {
    return state.loadingDocument
  },
  getPages: (state) => {
    return state.pages
  },
  getSelectedPage: (state) => {
    return state.selectedPages
  },
  getSelectedSharedCreationRole: (state) => {
    return state.selectedSharedCreationRole
  },
  getCreationId: (state) => {
    return state.creationId
  },
  getUpdateCreationType: (state) => {
    return state.updateCreationType
  },
  getCreationEditedSuccessfullY: (state) => {
    return state.creationEditedSuccesfully
  },
  getShowDeleteModal: (state) => {
    return state.showDeleteModal
  },
  getCreationsToDelete: (state) => {
    return state.creationsToDelete
  },
  getAddedPagesCount: (state) => {
    return state.addedPagesCount
  },
  getIsLoadingCreationOverview: (state) => {
    return state.isLoadingCreationOverview
  },
  getNewCreationDuplication: (state) => {
    return state.newCreationDuplication
  },
}

const actions = {
  setFilter({ commit }, filter) {
    commit('SET_FILTER', filter)
  },
  newCreationDuplication({ commit }, bool) {
    commit('SET_NEW_CREATION_DUPLICATION', bool)
  },
  resetReceiveDuplicationCreationState({ commit }) {
    commit('SET_RECEIVE_DUPLICATION_CREATION_STATE', {})
  },
  receiveDuplicationCreationState({ commit }, creation) {
    commit('SET_RECEIVE_DUPLICATION_CREATION_STATE', creation)
  },
  setDuplicationCreationState({ commit }, bool) {
    commit('SET_DUPLICATION_CREATION_STATE', bool)
  },
  async resetCreationOverview({ commit }) {
    await commit('RESET_CREATIONOVERVIEW', [])
  },
  async createCreation({ commit }, creation) {
    await createCreation(creation)
      .then((response) => {
        commit('SET_CREATION', response.data.data)
      })
      .catch((error) => {
        alert(error)
      })
  },
  async updateCreation({ commit }, payload) {
    commit('SET_ADDED_PAGES_COUNT', payload.creations.pagesToAdd)

    await updateCreation(payload.id, payload.creations)
      .then((response) => {
        const pages = response.data.data[0].magazine.magazinepages

        // commit("SET_PAGES", response.data.data);
        commit('SET_DOCUMENTS', pages)
        commit('SET_DOCUMENTS_FROM_API', JSON.parse(JSON.stringify(pages)))

        commit('SET_CREATION_EDITED_SUCCESSFULLY', true)
      })
      .catch((err) => {
        //

        console.log(err.message)
      })
  },
  // eslint-disable-next-line no-unused-vars
  async updateCreationTitle({ commit }, payload) {
    try {
      await updateCreationTitle(payload.id, payload.data)
    } catch (error) {
      //
    }
  },
  async deleteCreation({ commit }, creations) {
    for (let i = 0; i < creations.length; i++) {
      try {
        // eslint-disable-next-line no-unused-vars
        const response = await deleteCreation(creations[i].id)
        commit('DELETE_CREATION', creations[i])
      } catch (error) {
        //
      }
    }
  },
  async getCreations({ commit }) {
    try {
      const response = await getAllCreations()

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.magazines &&
        response.data.data.magazines.length
      ) {
        response.data.data.magazines.sort((a, b) => {
          // Get the higher date between lastContentChange and updated_at
          const lastContentChangeA =
            new Date(a.lastContentChange) > new Date(a.updated_at) ? a.lastContentChange : a.updated_at
          const lastContentChangeB =
            new Date(b.lastContentChange) > new Date(b.updated_at) ? b.lastContentChange : b.updated_at

          return new Date(lastContentChangeB) - new Date(lastContentChangeA)
        })

        commit('SET_CREATIONS', response.data.data.magazines)
      }
    } catch (error) {
      //
    }
  },
  async searchCreations({ state, commit, dispatch }, searchText) {
    try {
      if (searchText === '') {
        await dispatch('getCreations', { filter: state.filter, page: 1 })
        return
      }

      const response = await searchCreation({
        title: searchText,
      })

      if (response && response.data && response.data.data) {
        commit('SET_LAST_PAGE', 1)
      }

      if (response && response.data && response.data.data) {
        response.data.data.sort((a, b) => {
          // Get the higher date between lastContentChange and updated_at
          const lastContentChangeA =
            new Date(a.lastContentChange) > new Date(a.updated_at) ? a.lastContentChange : a.updated_at
          const lastContentChangeB =
            new Date(b.lastContentChange) > new Date(b.updated_at) ? b.lastContentChange : b.updated_at

          return new Date(lastContentChangeB) - new Date(lastContentChangeA)
        })

        commit('SET_CREATIONS', response.data.data)
      }
    } catch (error) {
      //
    }
  },
  async getSharedCreations({ commit }) {
    try {
      const response = await getSharedCreation()
      commit('SET_SHARED_CREATIONS', response.data.data)
    } catch (error) {
      //
    }
  },
  // eslint-disable-next-line no-unused-vars
  async duplicateCreation({ commit }, creationId) {
    try {
      await duplicateCreation(creationId)
      // commit("DUPLICATE_CREATION", response.data.data);
    } catch (error) {
      //
    }
  },
  async undoDelete({ commit }, creationId) {
    try {
      const response = await undoDelete(creationId)

      const pages = response.data.data[0].magazine.magazinepages
      commit('SET_DOCUMENTS', pages)
      commit('SET_DOCUMENTS_FROM_API', JSON.parse(JSON.stringify(pages)))

      commit('SET_CREATION_EDITED_SUCCESSFULLY', false)
    } catch (error) {
      //
    }
  },
  async undoAdd({ commit, getters }, creationId) {
    try {
      const pagesToAdd = getters.getAddedPagesCount
      const response = await undoAdd(creationId, { addPagesCount: pagesToAdd })

      const pages = response.data.data[0].magazine.magazinepages
      commit('SET_DOCUMENTS', pages)
      commit('SET_DOCUMENTS_FROM_API', JSON.parse(JSON.stringify(pages)))

      commit('SET_CREATION_EDITED_SUCCESSFULLY', false)
    } catch (error) {
      //
    }
  },
  async unsubscribeCreation({ commit }, creations) {
    for (let i = 0; i < creations.length; i++) {
      try {
        // eslint-disable-next-line no-unused-vars
        const response = await unsubscribe(creations[i].id)
        commit('UNSUBSCRIBE_CREATION', creations[i])
      } catch (error) {
        //
      }
    }
  },
  setSidebar({ commit }, status) {
    commit('SET_SIDEBAR', status)
  },
  setSelectedCreations({ commit }, creation) {
    commit('SET_SELECTED_CREATIONS', creation)
  },
  removeSelectedCreation({ commit }, creation) {
    commit('REMOVE_SELECTED_CREATION', creation)
  },
  setSidebarType({ commit }, sidebarType) {
    commit('SET_SIDEBARTYPE', sidebarType)
  },
  setSelectedPage({ commit }, page) {
    commit('SET_SELECTED_PAGE', page)
  },
  removeSelectedPage({ commit }, payload) {
    commit('REMOVE_SELECTED_PAGE', payload)
  },
  clearSelectedCreations({ commit }) {
    commit('CLEAR_SELECTED_CREATIONS')
  },
  setViewMyCreations({ commit }, status) {
    commit('SET_VIEW_MY_CREATIONS', status)
  },
  setSelectedSharedCreationRole({ commit }, role) {
    commit('SET_SELECTED_SHARED_CREATION_ROLE', role)
  },
  setCreationId({ commit }, creationId) {
    commit('SET_CREATION_ID', creationId)
  },
  setUpdateCreationType({ commit }, type) {
    commit('UPDATE_CREATION_TYPE', type)
  },
  setCreationEditedSuccessfully({ commit }, status) {
    commit('SET_CREATION_EDITED_SUCCESSFULLY', status)
  },
  setShowDeleteModal({ commit }, status) {
    commit('SET_SHOW_DELETE_MODAL', status)
  },
  setCreationsToDelete({ commit }, creations) {
    commit('SET_CREATIONS_TO_DELETE', creations)
  },
  setIsLoadingCreationOverview({ commit }, status) {
    commit('SET_IS_LOADING_CREATION_OVERVIEW', status)
  },
}

const mutations = {
  SET_LAST_PAGE(state, lastPage) {
    state.lastPage = lastPage
  },
  SET_SPINE(state, spine) {
    state.spine = spine
  },
  SET_FILTER(state, filter) {
    state.filter = filter
  },
  SET_NEW_CREATION_DUPLICATION(state, bool) {
    state.newCreationDuplication = bool
  },
  SET_RECEIVE_DUPLICATION_CREATION_STATE(state, creation) {
    state.duplicateCreationState = creation
  },
  SET_DUPLICATION_CREATION_STATE(state, bool) {
    state.duplicatedCreation = bool
  },
  RESET_CREATIONOVERVIEW(state, array) {
    state.creations = array
  },
  SET_CREATION(state, creation) {
    state.creation = creation
  },
  SET_CREATIONS(state, creations) {
    state.creations = creations
  },
  EXTEND_CREATIONS(state, creations) {
    state.creations.push(creations)
  },
  SET_SHARED_CREATIONS(state, creations) {
    state.sharedCreations = creations
  },
  DELETE_CREATION(state, creation) {
    const index = state.creations.indexOf(creation)
    state.creations.splice(index, 1)
  },
  UNSUBSCRIBE_CREATION(state, creation) {
    const index = state.sharedCreations.indexOf(creation)
    state.sharedCreations.splice(index, 1)
  },
  SET_SIDEBAR(state, status) {
    state.sidebarOpen = status
  },
  SET_SELECTED_CREATIONS(state, creation) {
    state.selectedCreations.push(creation)
  },
  REMOVE_SELECTED_CREATION(state, creation) {
    removeByAttr(state.selectedCreations, 'id', creation.id)
  },
  SET_SIDEBARTYPE(state, sidebarType) {
    state.sidebarType = sidebarType
  },
  SET_LOADING_DOCUMENT(state, payload) {
    state.loadingDocument = payload
  },
  SET_PAGES(state, pages) {
    state.pages = pages
  },
  SET_SELECTED_PAGE(state, page) {
    state.selectedPages.push(page)
  },
  REMOVE_SELECTED_PAGE(state, page) {
    removeByAttr(state.selectedPages, 'pageNumber', page.pageNumber)
  },
  CLEAR_SELECTED_CREATIONS(state) {
    state.selectedCreations = []
  },
  SET_VIEW_MY_CREATIONS(state, status) {
    state.viewMyCreations = status
  },
  SET_SELECTED_SHARED_CREATION_ROLE(state, role) {
    state.selectedSharedCreationRole = role
  },
  SET_CREATION_ID(state, creationId) {
    state.creationId = creationId
  },
  DUPLICATE_CREATION(state, creation) {
    state.creations.push(creation)
  },
  UPDATE_CREATION_TYPE(state, type) {
    state.updateCreationType = type
  },
  SET_CREATION_EDITED_SUCCESSFULLY(state, status) {
    state.creationEditedSuccesfully = status
    if (status) {
      setTimeout(() => {
        state.creationEditedSuccesfully = false
      }, 5000)
    }
  },
  SET_SHOW_DELETE_MODAL(state, status) {
    state.showDeleteModal = status
  },
  SET_CREATIONS_TO_DELETE(state, creations) {
    state.creationsToDelete = creations
  },
  SET_ADDED_PAGES_COUNT(state, count) {
    state.addedPagesCount = count
  },
  SET_IS_LOADING_CREATION_OVERVIEW(state, status) {
    state.isLoadingCreationOverview = status
  },
}

let removeByAttr = function (arr, attr, value) {
  let i = arr.length
  while (i--) {
    if (
      arr[i] &&
      // eslint-disable-next-line no-prototype-builtins
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1)
    }
  }
  return arr
}

export default {
  state,
  getters,
  actions,
  mutations,
}
