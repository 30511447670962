<template>
  <div>
    <div class="w-full pl-64 pr-12 pt-28">
      <PageTitle :title="headerInfo.title" :subtitle="headerInfo.subtitle" :image="headerInfo.image" />
    </div>

    <div id="orders">
      <b-container class="">
        <div class="sub_menu">
          <p @click="myOrderToggle" v-bind:class="{ active_sub_menu: selectedMyOrders }">
            {{ $t('orders.my.orders') }}
          </p>
          <p @click="myPdfToggle" v-bind:class="{ active_sub_menu: selectedMyOrders === false }">
            {{ $t('orders.my.download') }}
          </p>
        </div>
      </b-container>

      <div v-if="!isLoading">
        <b-container>
          <table
            v-if="
              selectedMyOrders === true &&
              this.$store.getters['getBackToOrders'] !== true &&
              this.$store.getters['getAllOrders'].length !== 0
            "
            style="width: 100%"
          >
            <tr>
              <th>{{ $t('orders.table.date') }}</th>
              <th>{{ $t('orders.table.number') }}</th>
              <th>{{ $t('orders.table.title') }}</th>
              <th>{{ $t('orders.table.price') }}</th>
              <th>{{ $t('orders.table.status') }}</th>
              <th>{{ $t('orders.table.actions') }}</th>
            </tr>
            <tr v-for="order in this.$store.getters['getAllOrders']" :key="order.id">
              <td class="order-number">{{ getDate(order.created_at) }}</td>
              <td>{{ order.id }}</td>
              <td class="titleTd" v-b-tooltip.hover :title="order.title">
                <p v-if="order.magazine">{{ order.magazine.title }}</p>
                <p v-else>N.V.T</p>
              </td>
              <td>€ {{ (order.totalPriceWithVAT / 100).toFixed(2).toString().replace('.', ',') }}</td>
              <td>
                <span class="table-status canceled">
                  {{ getOrderStatusText(order.orderstatus.title) }}
                </span>
              </td>
              <td>
                <Button
                  @click.native="showOrder(order.id)"
                  button-type="hint"
                  :button-text="$t('orders.button.information')"
                  icon="info-outline"
                  class="smallButton btnInformation"
                />
              </td>
            </tr>
          </table>

          <Orderdetail v-if="this.$store.getters['getBackToOrders']" />
        </b-container>

        <div v-if="this.$store.getters['getAllOrders'].length === 0 && selectedMyOrders" class="no-items pb-4">
          <div class="flex justify-center" style="margin-bottom: 1rem">
            <img :src="require('@/assets/no-orders-icon.png')" alt="" />
          </div>
          <div class="flex justify-center">
            <p class="text-lg max-w-sm" v-html="$t('orders.empty')"></p>
          </div>
        </div>

        <b-container>
          <table
            v-if="
              selectedMyOrders === false &&
              this.$store.getters['getBackToOrders'] !== true &&
              this.$store.getters['getAllPdfDownloads'].length !== 0
            "
            style="width: 100%"
          >
            <tr>
              <th>{{ $t('orders.table.number') }}</th>
              <th>{{ $t('orders.table.title') }}</th>
              <th>{{ $t('orders.table.pdftype') }}</th>
              <th>{{ $t('orders.table.actions') }}</th>
            </tr>
            <tr v-for="(pdf, index) in filterDownloads" :key="index">
              <td class="order-number">{{ pdf.id }}</td>
              <td class="titleTd" v-if="pdf.magazine === null">N.V.T.</td>
              <td class="titleTd" v-if="pdf.magazine !== null">
                <p>{{ pdf.magazine.title }}</p>
              </td>
              <td class="titleTd" v-else-if="pdf.magazine !== null && pdf.magazine.title.length > 48">
                <p v-b-tooltip.hover :title="pdf.magazine.title">
                  {{ pdf.magazine.title }}
                </p>
              </td>

              <td v-html="getPdfType(pdf)"></td>
              <td v-if="isDownloadAvailable(pdf.downloads)">
                <Button
                  button-type="hint"
                  :button-text="$t('orders.button.download')"
                  icon="download"
                  class="btnDownload"
                  @click.native="downloadPDF(pdf)"
                />
                <Button
                  button-type="outline_button_primary"
                  :button-text="$t('orders.button.preview')"
                  icon="view"
                  class="btnPreview"
                  @click.native="goToPdf(pdf)"
                />
              </td>
              <td v-else>
                <span class="pdf-generating">Generating PDF...</span>
              </td>
            </tr>
          </table>
        </b-container>

        <div
          v-if="selectedMyOrders === false && this.$store.getters['getAllPdfDownloads'].length === 0"
          class="no-items pb-4"
        >
          <div class="flex justify-center" style="margin-bottom: 1rem">
            <img :src="require('@/assets/no-pdf-icon.png')" alt="" />
          </div>
          <div class="flex justify-center">
            <p class="text-lg max-w-sm" v-html="$t('no.downloads')"></p>
          </div>
        </div>
      </div>
      <b-container v-else>
        <div class="loading-icon">
          <img src="/loading.gif" alt="loading" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import Orderdetail from '@/domains/orders/Orderdetail'
import PageTitle from '@/components/PageTitle.vue'
import bgImageOrders from '@/assets/orders-header.png'

export default {
  name: 'Orders',
  components: { Orderdetail, Button, PageTitle },
  data() {
    return {
      sub_menu_creation_active: false,
      selectedMyOrders: true,
      orderShown: false,
      isLoading: false,
      headerInfo: {
        title: this.$t('orders.header.title'),
        subtitle: this.$t('orders.header.description'),
        image: `url(${bgImageOrders})`,
      },
    }
  },
  computed: {
    // filterDownloads() {
    //   const unfilteredDownloads = JSON.parse(JSON.stringify(this.$store.getters['getAllPdfDownloads']))

    //   unfilteredDownloads.forEach((order) => this.seperateMultipleExtras(order, unfilteredDownloads))
    //   return unfilteredDownloads
    // },
    getOrderStatusText() {
      return (status) => {
        let text = ''

        switch (status && status.toLowerCase()) {
          case 'new':
            text = this.$t('webshop.orderstatus_new')
            break

          case 'paid':
            text = this.$t('webshop.orderstatus_paid')
            break

          case 'generated':
            text = this.$t('webshop.orderstatus_generated')
            break

          case 'transferred':
            text = this.$t('webshop.orderstatus_transferred')
            break

          case 'sent':
            text = this.$t('webshop.orderstatus_sent')
            break

          case 'printing':
            text = this.$t('webshop.orderstatus_printing')
            break

          case 'cancelled':
            text = this.$t('webshop.orderstatus_cancelled')
            break

          default:
            text = status
            break
        }

        return text
      }
    },

    filterDownloads() {
      const unfilteredDownloads = JSON.parse(JSON.stringify(this.$store.getters['getAllPdfDownloads']))
      const filteredDownloads = []

      for (const pdf of unfilteredDownloads) {
        if (pdf && pdf.orderstatus_id !== 1 && pdf.orderstatus_id !== 7) {
          const orderExtras = pdf && pdf.orderextras ? JSON.parse(pdf.orderextras) : []

          if (orderExtras.length) {
            const hasPdfDownload = orderExtras.filter(
              (orderExtra) => orderExtra.id && (orderExtra.id === 1 || orderExtra.id === 2)
            )

            if (hasPdfDownload.length) {
              for (const item of hasPdfDownload) {
                const pdfDownload = {
                  id: pdf.id,
                  magazine: pdf.magazine,
                  downloads: pdf.downloads && pdf.downloads.length ? [pdf.downloads[hasPdfDownload.indexOf(item)]] : [],
                  orderextras: [item],
                }

                filteredDownloads.push(pdfDownload)
              }
            }
          }
        }
      }

      return filteredDownloads
    },

    isDownloadAvailable() {
      return (downloads) => {
        if (downloads && downloads.length) {
          return downloads[0].fileUrl
        }

        return false
      }
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch('getOrders')
    await this.$store.dispatch('getDownloads')
    this.isLoading = false
  },
  methods: {
    seperateMultipleExtras(order, unorderdList) {
      let index = unorderdList.indexOf(order)

      for (const [i, download] of Object.entries(order.downloads)) {
        if (download === null) continue

        if (i == 0) {
          unorderdList[index].downloads = [download]
          unorderdList[index].orderextra_ids = download && download.orderextra_ids
        } else {
          const orderClone = JSON.parse(JSON.stringify(order))
          orderClone.downloads = [download]
          orderClone.orderextra_ids = download.orderextra_ids
          unorderdList.splice(index, 0, orderClone)
          unorderdList.join()
        }
        index++
      }

      return unorderdList
    },
    goToPdf(pdf) {
      window.open(pdf.downloads[0].fileUrl)
    },
    downloadPDF(pdf) {
      if (pdf && pdf.downloads && pdf.downloads.length) {
        for (const item of pdf.downloads) {
          let url = item.fileUrl

          //Create XMLHTTP Request.
          let req = new XMLHttpRequest()
          req.open('GET', url, true)
          req.responseType = 'blob'
          req.onload = function () {
            //Convert the Byte Data to BLOB object.
            let blob = new Blob([req.response], { type: 'application/octetstream' })

            //Check the Browser type and download the File.
            let isIE = false || !!document.documentMode

            if (isIE) {
              window.navigator.msSaveBlob(blob, pdf?.id)
            } else {
              let _url = window.URL || window.webkitURL
              let link = _url.createObjectURL(blob)
              let a = document.createElement('a')
              a.setAttribute('download', pdf?.id + '-' + item.id + '.pdf')
              a.setAttribute('href', link)
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
            }
          }
          req.send()
        }
      }
    },
    async myPdfToggle() {
      await this.$store.commit('SET_ORDERS', [])
      this.selectedMyOrders = false

      await this.$store.dispatch('getDownloads')

      if (this.$store.getters['getBackToOrders']) {
        await this.$store.dispatch('setBackToOrders', false)
      }
    },
    async myOrderToggle() {
      this.selectedMyOrders = true
      this.isLoading = true

      await this.$store.dispatch('getOrders')
      await this.$store.dispatch('getDownloads')

      this.isLoading = false
      if (this.$store.getters['getBackToOrders']) {
        await this.$store.dispatch('setBackToOrders', false)
      }
    },
    showOrder(orderId) {
      this.$store.dispatch('setBackToOrders', true)
      this.$store.dispatch('previewOrder', orderId)
    },
    getDate(dateString) {
      const date = new Date(dateString)
      return (
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '-' +
        date.getFullYear()
      )
    },
    getPdfType(pdf) {
      const orderExtras = pdf && pdf.orderextras ? pdf.orderextras : []

      const orderExtra =
        (orderExtras &&
          orderExtras.find((extra) => {
            return extra.name && extra.name.includes('pdf_')
          })) ||
        null

      if (orderExtra && orderExtra.id === 1) {
        return this.$t('orders.table.pdftype1')
      } else if (orderExtra && orderExtra.id === 2) {
        return this.$t('orders.table.pdftype2')
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped>
.pdf-generating {
  color: black;
  font-size: 14px;
  font-weight: 500;
}
</style>
