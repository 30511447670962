import httpClient from '../../../httpClient'

const CREATION_END_POINT = 'v3/creation'
const NEW_CREATION_END_POINT = 'v3/maker/creations'
const INVITATION_END_POINT = 'v3/invitation'
const PREVIEW_END_POINT = 'v3/shared'

const getCreation = (filter, page) => httpClient.get(`${CREATION_END_POINT}?filter=${filter}&page=${page}`)
const getAllCreations = () => httpClient.get(`${NEW_CREATION_END_POINT}`)
const searchCreation = (data) => httpClient.post(`${CREATION_END_POINT}/search`, data)
const getCreationPages = (creationId) => httpClient.get(`${CREATION_END_POINT}/${creationId}/pages`)
const getSharedCreation = () => httpClient.get(`${CREATION_END_POINT}/shared`)
const createCreation = (data) => httpClient.post(`${CREATION_END_POINT}`, data)
const getCreationMembers = (creationId) => httpClient.get(`${CREATION_END_POINT}/${creationId}/members`)
const updateCreation = (creationId, data) => httpClient.patch(`${CREATION_END_POINT}/${creationId}`, data)
const deleteCreation = (creationId) => httpClient.delete(`${CREATION_END_POINT}/${creationId}`)
const updateCreationTitle = (creationId, data) => httpClient.patch(`${CREATION_END_POINT}/${creationId}/title`, data)
const duplicateCreation = (creationId) => httpClient.post(`${CREATION_END_POINT}/duplicate/${creationId}`)
const undoDelete = (creationId) => httpClient.patch(`${CREATION_END_POINT}/${creationId}/undodelete`)
const undoAdd = (creationId, data) => httpClient.patch(`${CREATION_END_POINT}/${creationId}/undoadd`, data)
const unsubscribe = (creationId) => httpClient.patch(`${INVITATION_END_POINT}/unsubscribe/${creationId}`)

const getPreview = (creationId) => httpClient.get(`${CREATION_END_POINT}/${creationId}/pageflip`)

const getSpine = (creationId) => httpClient.get(`${CREATION_END_POINT}/${creationId}/spine`)

export {
  getCreation,
  getCreationMembers,
  getCreationPages,
  getSharedCreation,
  createCreation,
  updateCreation,
  deleteCreation,
  updateCreationTitle,
  duplicateCreation,
  undoDelete,
  undoAdd,
  unsubscribe,
  getPreview,
  getSpine,
  searchCreation,
  getAllCreations,
}
