<template>
  <div>
    <DocumentModal v-if="this.$store.getters['getShowModal']" />

    <VideoPopup v-if="this.$store.getters['getVideoPopupActive']" />

    <div class="video-popup-overlay" v-if="this.$store.getters['getVideoPopupActive']"></div>

    <div class="pl-64 pr-12 pt-28">
      <div>
        <div class="w-full">
          <PageTitle :title="headerInfo.title" :subtitle="headerInfo.subtitle" :image="headerInfo.image" />
        </div>

        <!-- Magzine awards nomination notice -->
        <div
          v-if="filteredNominatedMagazines && filteredNominatedMagazines.length && showAwardUI"
          class="banner-header"
        >
          <span class="flex justify-start items-center">
            <span class="flex flex-col justify-center items-center" style="margin-right: 8px">
              <img :src="require('@/assets/award_badge.svg')" alt="" style="width: 30px; height: auto" />
              <img
                :src="require('@/assets/award_badge.svg')"
                class="transform rotate-180"
                alt=""
                style="width: 30px; height: auto"
              />
            </span>
            <span>
              <span style="font-weight: 600">{{
                (getLoggedInUser && (getLoggedInUser.firstName || getLoggedInUser.name)) || ''
              }}</span
              >, {{ $t('magazine.award.creations.overview.page.notice.first.part') }}
              {{ filteredNominatedMagazines.length }}
              {{ $t('magazine.award.creations.overview.page.notice.second.part') }}
              <span
                style="
                  color: #2a65c4;
                  border-bottom: 1px solid #2a65c4;
                  padding-bottom: 2px;
                  display: inline-block;
                  cursor: pointer;
                "
                @click="$store.commit('SET_SHOW_AWARD_GUIDE_MODAL', true)"
              >
                “{{ $t('magazine.award.creations.overview.page.notice.third.part') }}”
              </span>
            </span>
          </span>
        </div>

        <!-- Search creation -->
        <div class="mt-4 flex justify-start items-end space-x-2" style="max-width: 450px">
          <div class="btn-container block flex-grow">
            <Input
              id="search"
              v-model="searchText"
              name="search"
              type="text"
              :is-focused="isFocused"
              placeholder-text="For ex. Sophie’s birthday"
              autocomplete="off"
              :is-disabled="isLoading"
              :class="{ errorBorder: this.errorStatus, 'input-custom': searchText }"
              @after-focus="isFocused = false"
              @input="handleInput"
            />
            <button
              v-show="showCrossBtn"
              class="p-0 border-0 bg-transparent position-absolute btn-position"
              @click="
                () => {
                  searchText = ''
                  isFocused = true
                  showCrossBtn = false
                }
              "
            >
              <svg
                width="16"
                height="15"
                class="w-4"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.32812 8L14.4062 2.96094C14.8229 2.51823 14.8229 2.0625 14.4062 1.59375C13.9375 1.17708 13.4818 1.17708 13.0391 1.59375L8 6.67188L2.96094 1.59375C2.49219 1.17708 2.03646 1.17708 1.59375 1.59375C1.17708 2.0625 1.17708 2.51823 1.59375 2.96094L6.67188 8L1.59375 13.0391C1.17708 13.4818 1.17708 13.9375 1.59375 14.4062C1.82812 14.5885 2.0625 14.6797 2.29688 14.6797C2.50521 14.6797 2.72656 14.5885 2.96094 14.4062L8 9.32812L13.0391 14.4062C13.2734 14.5885 13.4948 14.6797 13.7031 14.6797C13.9375 14.6797 14.1719 14.5885 14.4062 14.4062C14.8229 13.9375 14.8229 13.4818 14.4062 13.0391L9.32812 8Z"
                  fill="#111111"
                />
              </svg>
            </button>
          </div>
        </div>

        <div
          v-if="!isLoading"
          class="creations_container"
          v-bind:class="{
            container_resize_creation: gridResize === 6,
            smallWidth: $store.getters['getSidebarOpen'],
          }"
        >
          <div>
            <div
              v-if="$store.getters['getCreations'] !== undefined && $store.getters['getCreations'].length !== 0"
              class="flex gap-x-8 flex-wrap items-start justify-start"
            >
              <div
                v-if="!this.$store.getters['getSidebarOpen']"
                style="
                  width: 234px;
                  height: 283px;
                  margin-top: 20px;
                  margin-bottom: 40px;
                  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
                  padding: 8px;
                "
                class="relative z-0"
              >
                <div class="w-full h-full rounded overflow-hidden relative">
                  <img
                    :src="require('@/assets/start_template.png')"
                    alt=""
                    class="w-full h-full object-cover object-center"
                  />

                  <button
                    class="font-semibold text-sm absolute bottom-0 left-1/2 transform -translate-x-1/2 text-white text-center inline-block rounded whitespace-nowrap px-6 py-2.5 bg-wg-light-accent hover:bg-wg-light-accent-hover mb-9 z-10"
                    @click="
                      $store.commit('SET_SELECTED_TEMPLATE', firstTemplate)
                      $store.commit('SET_SHOW_TEMPLATE_MODAL', true)
                    "
                  >
                    {{ $t('my.creations.start.from.template') }}
                  </button>

                  <div
                    class="w-full absolute bottom-0 left-0 z-0"
                    style="
                      height: 142px;
                      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
                    "
                  ></div>
                </div>
              </div>

              <div
                v-if="!this.$store.getters['getSidebarOpen']"
                style="
                  width: 234px;
                  height: 283px;
                  margin-top: 20px;
                  margin-bottom: 40px;
                  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
                  padding: 8px;
                "
                class="relative z-0"
              >
                <div class="w-full h-full rounded overflow-hidden relative">
                  <img
                    :src="require('@/assets/start_scratch.png')"
                    alt=""
                    class="w-full h-full object-cover object-center"
                  />

                  <button
                    class="font-semibold text-sm absolute bottom-0 left-1/2 transform -translate-x-1/2 text-white text-center inline-block rounded whitespace-nowrap px-6 py-2.5 bg-wg-light-accent hover:bg-wg-light-accent-hover mb-9 z-10"
                    @click="$store.commit('SET_SHOW_SCRATCH_MODAL', true)"
                  >
                    {{ $t('my.creations.start.from.scratch') }}
                  </button>

                  <div
                    class="w-full absolute bottom-0 left-0 z-0"
                    style="
                      height: 142px;
                      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
                    "
                  ></div>
                </div>
              </div>

              <div v-for="(creation, index) in filteredCreations" :key="index">
                <Creation :creation="creation" @reload-data="reloadData" />
              </div>
            </div>

            <!-- If user has no creations -->
            <div v-else class="" style="margin-top: 60px">
              <div class="gap-x-6 flex justify-start items-stretch" style="max-width: 1152px">
                <Card
                  :data="startCreationTemplates"
                  @on-click="
                    $store.commit('SET_SELECTED_TEMPLATE', firstTemplate)
                    $store.commit('SET_SHOW_TEMPLATE_MODAL', true)
                  "
                />
                <Card :data="startCreationScratch" @on-click="$store.commit('SET_SHOW_SCRATCH_MODAL', true)" />
              </div>
            </div>
          </div>
        </div>

        <div v-else class="loading-icon">
          <img src="/loading.gif" alt="loading" />
        </div>
      </div>

      <ShareCreation v-if="this.$store.getters['getLoadingDocument']" />
      <SideBarRight />
      <DeleteModal v-if="this.$store.getters['getShowDeleteModal']" />
    </div>
  </div>
</template>

<script>
import Creation from '@/components/Creation'
import DocumentModal from '@/components/documentmodal/DocumentModal'
import SideBarRight from '@/components/sidebarright/SideBarRight'
import Input from '@/components/Input'
import ShareCreation from './ShareCreation'
import DeleteModal from '../../components/DeleteModal'
import VideoPopup from '../../components/video/VideoPopup'
import PageTitle from '@/components/PageTitle.vue'
import Card from '../home/Card.vue'

import bgImageCreations from '@/assets/creations-header.png'

import cardImageTemplate from '@/assets/start_template.png'
import cardImageScratch from '@/assets/start_scratch.png'

export default {
  name: 'CreationsOverview',
  components: {
    VideoPopup,
    DeleteModal,
    Input,
    SideBarRight,
    Creation,
    ShareCreation,
    DocumentModal,
    PageTitle,
    Card,
  },

  data() {
    return {
      errorStatus: false,
      searchText: '',
      isFocused: false,
      showCrossBtn: false,
      showBindingOptions: false,
      selectedMycreations: true,
      options: [
        { value: 'all', text: this.$t('creations.overview.filter.all') },
        { value: 'owner', text: 'Owner' },
        { value: 'chiefmaker', text: 'Chiefmaker' },
        { value: 'maker', text: 'Maker' },
      ],
      sidebarIsOpen: false,
      isLoading: false,
      selectedOption: '',
      currentFilter: 'all',
      currentPage: 1,
      newCreationDuplication: false,
      headerInfo: {
        title: this.$t('my.creations.header.title'),
        subtitle: this.$t('my.creations.header.description'),
        image: `url(${bgImageCreations})`,
      },
      startCreationScratch: {
        title: 'Your dream creation awaits',
        description:
          'Create something unique from scratch. With a blank canvas and your imagination, there are no limits.',
        buttonText: 'Start from scratch',
        image: cardImageScratch,
      },
      startCreationTemplates: {
        title: 'Easier with templates',
        description: 'Get inspired and save time by using a template as a launchpad for your creative design journey.',
        buttonText: 'Start from a template',
        image: cardImageTemplate,
      },
    }
  },

  watch: {
    newCreationDuplication: {
      immediate: false,
    },
    async '$store.state.creation.newCreationDuplication'() {
      this.isLoading = true

      await this.$store.dispatch('getCreations')

      await this.$store.dispatch('newCreationDuplication', false)

      this.isLoading = false
    },
  },
  methods: {
    handleInput(val) {
      this.errorStatus = false

      if (val) {
        this.showCrossBtn = true
      } else {
        this.showCrossBtn = false
      }
    },

    // async getCreationsBasedOnFilter(object) {
    //   this.isLoading = true
    //   await this.$store.dispatch('resetCreationOverview')
    //   this.currentPage = 1
    //   this.scrolledToBottom = false
    //   this.selectedOption = object[0]

    //   let request = {
    //     filter: object[0],
    //     page: this.currentPage,
    //     isOtherPage: false,
    //   }

    //   await this.$store.dispatch('getCreations', request)

    //   this.currentPage = 2

    //   request.page = this.currentPage
    //   request.isOtherPage = true

    //   await this.$store.dispatch('getCreations', request)

    //   this.currentFilter = object[0]
    //   await this.$store.dispatch('setFilter', request.filter)
    //   this.isLoading = false
    // },
    closeSidebar() {
      if (this.$store.getters['getSidebarOpen']) {
        this.$store.dispatch('setSidebar', false)
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
        this.$store.dispatch('setSidebarType', 'default')
        this.$store.dispatch('clearSelectedCreations')
        this.$store.dispatch('setSelectedSharedCreationRole', 'none')
      }
    },
    toggledSubMenuMyCreations() {
      this.selectedMycreations = true
      this.$store.dispatch('setViewMyCreations', true)
      if (this.$store.getters['getSidebarOpen']) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      }
      this.$store.dispatch('setSidebar', false)
      this.$store.dispatch('clearSelectedCreations')
      this.$store.dispatch('setSelectedSharedCreationRole', 'none')
    },
    async toggledSubMenuShared() {
      if (this.$store.getters['getSidebarOpen']) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      }
      await this.$store.dispatch('setSidebar', false)
      await this.$store.dispatch('setSelectedSharedCreationRole', 'none')
    },
    async reloadData() {
      this.isLoading = true

      await this.$store.dispatch('getCreations')

      this.isLoading = false
    },
  },

  computed: {
    allCreations() {
      return this.$store.getters['getCreations']
    },
    filteredCreations() {
      if (!this.searchText) {
        return this.allCreations
      }

      return this.allCreations.filter((creation) => {
        return creation.title && creation.title.toLowerCase().includes(this.searchText.toLowerCase())
      })
    },
    lengthCheck() {
      if (this.$store.getters['getSharedCreations'] !== undefined) {
        if (this.$store.getters['getSharedCreations'].length === 0) {
          return false
        }
        return true
      }
      return false
    },
    gridResize() {
      let size = 4

      if (this.$store.getters['getSidebarOpen']) {
        document.getElementsByClassName('creations_container')[0].maxWidth = '500px'
        size = 4
      }

      return size
    },
    firstTemplate() {
      const templates = this.$store.state.home.templates
      return templates && templates.length ? templates[0] : null
    },
    getLoggedInUser() {
      return this.$store.getters['getLoggedInUser']
    },
    activeCampaign() {
      return this.$store.state.magazineAward.activeCampaign
    },
    nominatedMagazines() {
      return this.$store.state.magazineAward.nominatedMagazines
    },
    filteredNominatedMagazines() {
      return (
        this.nominatedMagazines &&
        this.nominatedMagazines.filter((magazine) => {
          return magazine.Status !== 'Rejected' && magazine.Status !== 'UnNominated'
        })
      )
    },
    showAwardUI() {
      return this.$store.state.magazineAward.showAwardUI
    },
    checkLindaSetting() {
      const settings = this.$store.getters['getSettings']

      const linda = settings.find((item) => item.name === 'LINDA_CARRIER')
      return (linda && linda.active) || false
    },
  },
  async created() {
    this.isLoading = true

    setTimeout(async () => {
      document.body.classList.remove('document-body')
      if (this.$route.query.token !== undefined && this.$route.query.magazine_id !== undefined) {
        const data = { id: this.$route.query.magazine_id, token: this.$route.query.token }
        await this.$store.dispatch('acceptInvitation', data)
      }

      let request = {
        filter: 'all',
        page: 1,
        isOtherPage: false,
      }

      await this.$store.dispatch('getCreations', request)

      request.page = 2
      request.isOtherPage = true

      await this.$store.dispatch('getCreations', request)
      this.currentPage = 2

      if (window.innerHeight + window.scrollY > 1650) {
        this.currentPage = this.currentPage += 1
        request.page = this.currentPage

        await this.$store.dispatch('getCreations', request)
      }

      if (this.$route.query.token !== undefined) {
        await this.toggledSubMenuShared()
      }
      this.isLoading = false
    }, 500)
  },
  async mounted() {
    // Add an event to window that will trigger page refresh when the browser tab is visible again
    window.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        await this.reloadData()
      }
    })
  },
  unmouted() {
    window.removeEventListener('visibilitychange')
    window.onscroll = null
  },
}
</script>

<style scoped>
.banner-header {
  color: #111;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  width: max-content;
}

.btn-position {
  right: 0.75rem;
  top: 35%;
  transform: translateY(-50%);

  margin: auto;
}

.btn-container {
  position: relative;
}

.input-custom >>> input {
  padding-right: 2.25rem;
  width: 100%;
}
</style>
