<template>
  <div>
    <div id="document" v-if="$store.getters['getDocumentsFromApi'].length !== 0">
      <div class="overlay" v-if="titleHasNotBeenSet">
        <TitlePopup />
      </div>

      <div class="document-menu">
        <div
          @click="openModal()"
          class="document-menu-item"
          :class="disableButtons && 'disable-btn'"
          v-if="this.$store.getters['getDocumentRole'] !== 3"
        >
          <i class="icon-settings"></i>
          <p>{{ $t('documentoverview.button.settings') }}</p>
        </div>
        <router-link
          class="order-link"
          :class="disableButtons && 'disable-btn'"
          :to="'/preview/' + this.$route.params.id"
        >
          <div class="document-menu-item" :class="disableButtons && 'disable-btn'">
            <i class="icon-view"></i>
            <p>{{ $t('documentoverview.button.preview') }}</p>
          </div>
        </router-link>
        <router-link
          v-if="this.$store.getters['getDocumentRole'] !== 3"
          class="order-link"
          :class="disableButtons && 'disable-btn'"
          :to="{ name: 'OrderCheckout', params: { id: this.$route.params.id } }"
        >
          <div class="document-menu-item btnOrder" :class="disableButtons && 'disable-btn'">
            <i class="icon-download"></i>
            <p>{{ $t('documentoverview.button.order') }}</p>
          </div>
        </router-link>
      </div>

      <b-container>
        <div
          class="pages-container"
          :class="{
            pagesContainerMagazine:
              !this.$store.getters['getViewedAsCreation'] &&
              (this.$store.getters['getDocumentRole'] !== 3 || this.$store.getters['getEditorPreview']),
            pagesContainerLinda: this.$store.getters['getIsLindaMagazine'],
            pagesContainerGlossy: this.$store.getters['getIsGlossyMagazine'],
            lindaSize: getIsLindaMagazine && !this.$store.getters['getViewedAsCreation'],
          }"
        >
          <!--        <div class="selection-type-document" v-if="!this.$store.getters['getViewedAsCreation']">-->
          <!--          <p>Spread view</p>-->
          <!--        </div>-->
          <!--        <div class="selection-type-document" v-else>-->
          <!--          <p>Page view</p>-->
          <!--        </div>-->

          <b-row
            v-if="
              this.$store.getters['getViewedAsCreation'] ||
              (this.$store.getters['getDocumentRole'] === 3 && !this.$store.getters['getEditorPreview'])
            "
          >
            <b-col v-if="getFrontCover !== undefined" lg="2" :class="{ lindaColSize: getIsLindaMagazine }">
              <Document
                class="documentInOverview"
                :page="getFrontCover.number"
                :type="getStatus(getFrontCover.status)"
                :pageCover="getFrontCover.type"
                :magazine-page-id="getFrontCover.id"
                :magazine-id="getFrontCover.magazine_id"
                :isGlossy="getIsGlossyMagazine"
                :imageUrl="createThumbnailUrl(magazine_id, getFrontCover.id)"
              />
            </b-col>
            <b-col
              lg="2"
              v-for="document in sortDocuments"
              :key="document.id"
              :class="{ lindaColSize: getIsLindaMagazine }"
            >
              <Document
                class="documentInOverview"
                :page="document.number"
                :type="getStatus(document.status)"
                :magazine-page-id="document.id"
                :magazine-id="document.magazine_id"
                :isGlossy="getIsGlossyMagazine"
                :imageUrl="createThumbnailUrl(magazine_id, document.id)"
              />
            </b-col>
            <b-col v-if="getBackCover !== undefined" lg="2" :class="{ lindaColSize: getIsLindaMagazine }">
              <Document
                class="documentInOverview"
                :page="getBackCover.number"
                :type="getStatus(getBackCover.status)"
                :pageCover="getBackCover.type"
                :magazine-page-id="getBackCover.id"
                :magazine-id="getBackCover.magazine_id"
                :isGlossy="getIsGlossyMagazine"
                :imageUrl="createThumbnailUrl(magazine_id, getBackCover.id)"
              />
            </b-col>
          </b-row>

          <b-row v-else>
            <div
              style="width: 100%"
              v-if="this.$store.getters['getIsLindaMagazine'] || this.$store.getters['getIsGlossyMagazine']"
            >
              <b-row class="spine-row">
                <b-col v-if="getBackCover !== undefined" lg="6" :class="{ lindaColSize: getIsLindaMagazine }">
                  <Document
                    class="documentInOverview"
                    :page="getBackCover.number"
                    :type="getStatus(getBackCover.status)"
                    :pageCover="getBackCover.type"
                    :magazine-page-id="getBackCover.id"
                    :magazine-id="getBackCover.magazine_id"
                    :imageUrl="createThumbnailUrl(magazine_id, getBackCover.id)"
                    :isLinda="getIsLindaMagazine"
                    :isGlossy="getIsGlossyMagazine"
                    :frontCover="getFrontCover.id"
                  />
                </b-col>

                <Spine :img="spine" />

                <b-col v-if="getFrontCover !== undefined" lg="6" :class="{ lindaColSize: getIsLindaMagazine }">
                  <Document
                    class="documentInOverview"
                    :page="getFrontCover.number"
                    :type="getStatus(getFrontCover.status)"
                    :pageCover="getFrontCover.type"
                    :magazine-page-id="getFrontCover.id"
                    :magazine-id="getFrontCover.magazine_id"
                    :imageUrl="createThumbnailUrl(magazine_id, getFrontCover.id)"
                    :isLinda="getIsLindaMagazine"
                    :isGlossy="getIsGlossyMagazine"
                    :backCover="getBackCover.id"
                  />
                </b-col>
              </b-row>
            </div>
            <b-col
              lg="6"
              v-if="!this.$store.getters['getIsLindaMagazine'] && !this.$store.getters['getIsGlossyMagazine']"
              :class="{ lindaColSize: getIsLindaMagazine }"
            ></b-col>
            <b-col
              v-if="
                getFrontCover !== undefined &&
                !this.$store.getters['getIsLindaMagazine'] &&
                !this.$store.getters['getIsGlossyMagazine']
              "
              lg="6"
              :class="{ lindaColSize: getIsLindaMagazine }"
            >
              <Document
                class="documentInOverview"
                :page="getFrontCover.number"
                :type="getStatus(getFrontCover.status)"
                :pageCover="getFrontCover.type"
                :magazine-page-id="getFrontCover.id"
                :magazine-id="getFrontCover.magazine_id"
                :isGlossy="getIsGlossyMagazine"
                :imageUrl="createThumbnailUrl(magazine_id, getFrontCover.id)"
              />
            </b-col>
            <b-col
              lg="6"
              v-for="document in sortDocuments"
              :key="document.id"
              :class="{ lindaColSize: getIsLindaMagazine }"
            >
              <Document
                class="documentInOverview"
                :page="document.number"
                :type="getStatus(document.status)"
                :magazine-page-id="document.id"
                :magazine-id="document.magazine_id"
                :isGlossy="getIsGlossyMagazine"
                :imageUrl="createThumbnailUrl(magazine_id, document.id)"
              />
            </b-col>
            <b-col v-if="sortDocuments.length % 2" :class="{ lindaColSize: getIsLindaMagazine }"></b-col>
            <b-col
              v-if="
                getBackCover !== undefined &&
                !this.$store.getters['getIsLindaMagazine'] &&
                !this.$store.getters['getIsGlossyMagazine']
              "
              lg="6"
              :class="{ lindaColSize: getIsLindaMagazine }"
            >
              <Document
                class="documentInOverview"
                :page="getBackCover.number"
                :type="getStatus(getBackCover.status)"
                :pageCover="getBackCover.type"
                :magazine-page-id="getBackCover.id"
                :magazine-id="getBackCover.magazine_id"
                :isGlossy="getIsGlossyMagazine"
                :imageUrl="createThumbnailUrl(magazine_id, getBackCover.id)"
              />
            </b-col>
          </b-row>
        </div>
      </b-container>
      <!--    <SidebarDocument v-if="!invited"/>-->
      <SideBarRight
        :is-creation-nominated="isCreationNominated"
        :is-linda="getIsLindaMagazine"
        :is-glossy="getIsGlossyMagazine"
        :candidate="candidate"
        :magazine-id="magazine_id"
        :front-cover-id="(getFrontCover && getFrontCover.id) || ''"
        :disable-buttons="disableButtons"
        :video-url="videoUrl"
        @set-video-url="(val) => (videoUrl = val)"
      />
      <DocumentSettings v-if="this.$store.getters['getSelectedOptions'] || this.$store.getters['getInviteMenu']" />
      <div
        class="overlay"
        v-if="this.$store.getters['getSelectedOptions'] || this.$store.getters['getInviteMenu']"
        style="z-index: 9999"
      ></div>

      <DocumentModal v-if="this.$store.getters['getShowModal']" />

      <ViewTeam v-if="this.$store.getters['getShowViewTeamModal']" />

      <VideoModalTutorial v-if="videoUrl" @on-close="videoUrl = ''" :video-url="videoUrl" />
    </div>

    <b-container v-else>
      <div class="loading-icon">
        <img src="/loading.gif" alt="loading" />
      </div>
    </b-container>

    <UnNominateModal
      v-if="showUnNominateModal && showAwardUI && !awardVotingEnded"
      :candidate="candidate"
      @on-success="onNominationSubmitSuccess"
    />
    <UnNominationConfirmationModal v-if="showUnNominateConfirmationModal && showAwardUI && !awardVotingEnded" />

    <ParticipateAwardModal
      v-if="showParticipateAwardModal && showAwardUI && !awardVotingEnded"
      :front-cover-id="getFrontCover.id"
      @on-success="onNominationSubmitSuccess"
    />
    <ParticipateAwardConfirmationModal
      v-if="showParticipateAwardConfirmationModal && showAwardUI && !awardVotingEnded"
    />
  </div>
</template>

<script>
//import SidebarDocument from "../../components/SidebarDocument";
import SideBarRight from '@/components/sidebarright/SideBarRight'
import Document from '../../components/Document'
import DocumentModal from '@/components/documentmodal/DocumentModal'
import UnNominateModal from '@/components/magazine-award/UnNominateModal'
import UnNominationConfirmationModal from '@/components/magazine-award/UnNominationConfirmationModal'
import ParticipateAwardModal from '@/components/magazine-award/ParticipateAwardModal'
import ParticipateAwardConfirmationModal from '@/components/magazine-award/ParticipateAwardConfirmationModal'

import ViewTeam from '@/components/viewteam/ViewTeam'
import { getSpine } from '../creations/creationEndpoint'
import createThumbnailUrl from '../../mixins/createThumbnailUrl'
import axios from 'axios'
import Spine from '../../components/Spine'
import TitlePopup from '../../components/TitlePopup'
import VideoModalTutorial from '../../components/video/VideoModalTutorial.vue'

export default {
  name: 'DocumentOverview.vue',

  components: {
    TitlePopup,
    Spine,
    Document,
    SideBarRight,
    DocumentModal,
    ViewTeam,
    UnNominateModal,
    UnNominationConfirmationModal,
    ParticipateAwardModal,
    ParticipateAwardConfirmationModal,
    VideoModalTutorial,
  },

  mixins: [createThumbnailUrl],

  data() {
    return {
      invited: this.$route.query.invited === 'true',
      token: this.$route.query.token,
      magazine_id: this.$route.params.id,
      spine: '',
      videoUrl: '',
      // documents: this.$store.getters["getDocumentsFromApi"],
      isCreationNominated: null,
      candidate: null,
      disableButtons: null,
    }
  },

  async created() {
    // await this.$store.dispatch('clearDocumentsFromApi')
    window.onpopstate = () => {
      window.location.href = '/'
    }

    if (this.token !== undefined) {
      const data = { id: this.magazine_id, token: this.token }
      await this.$store.dispatch('acceptInvitation', data)
    }

    await this.$store.dispatch('setDocuments', this.$route.params.id)
    await this.$store.dispatch('setSettingsChanged', false)
    await this.setPusher()
    if (this.$store.getters['getIsLindaMagazine'] || this.$store.getters['getIsGlossyMagazine']) {
      const response = await getSpine(this.$route.params.id)
      this.$store.commit('SET_SPINE', response.data.data)
    }

    await this.createSpineThumbnailUrl(this.$route.params.id)
  },

  methods: {
    async onNominationSubmitSuccess() {
      await this.setIsCreationNominated()

      await this.$store.dispatch(
        'getUsersNominatedMagazines',
        (this.getLoggedInUser && this.getLoggedInUser.email) || ''
      )
    },
    async createSpineThumbnailUrl(creationId) {
      let spineUrl = ''
      const randomInt = Math.floor(Math.random() * 1000)

      try {
        await axios.get(
          `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${creationId}/png_thumbnail/spine.png`
        )
        spineUrl = `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${creationId}/png_thumbnail/spine.png?id=${randomInt}`
      } catch (e) {
        spineUrl = this.getIsLindaMagazine ? `/spine-linda.png?id=${randomInt}` : `/spine-glossy.png?id=${randomInt}`
      }

      this.spine = spineUrl
    },
    setPusher() {
      let channel = this.$pusher.subscribe('private-creation.' + this.magazine_id)
      const that = this

      channel.bind('CreationCanvasUpdated', function (data) {
        const payload = {
          canvas: data.canvas,
          pages: that.getDocuments(),
        }

        payload.canvas.imageUrl = that.createThumbnailUrl(data.creation.id, data.canvas.id)

        that.$store.dispatch('clearDocumentsFromApi')
        that.$store.dispatch('setPageFromPusher', payload)
        that.$store.commit('SET_CREATION_STATUS', data.creation.status)
        that.documents = that.$store.getters['getDocumentsFromApi']
      })

      channel.bind('CreationSpineUpdated', function (spine) {
        that.$store.commit('SET_SPINE', spine.spine)
        that.$store.commit('SET_CREATION_STATUS', spine.creation.status)
        that.createSpineThumbnailUrl(that.$route.params.id)
      })

      channel.bind('StartFromTemplateCanvassesProgress', function (data) {
        const canvasses = (data && data.progress && data.progress.ca) || []

        for (let i = 0; i < canvasses.length; i++) {
          const canvas = canvasses[i]

          that.$store.commit('SET_DOCUMENT_STATUS', canvas)
        }

        if (data && data.progress && data.progress.cr && data.progress.cr.s === 1) {
          that.disableButtons = false
          that.getDocuments()
        }
      })
    },
    getDocuments() {
      return this.$store.getters['getDocumentsFromApi']
    },
    openModal() {
      this.$store.dispatch('setShowInviteForm', false)
      this.$store.dispatch('setShowModal', true)
    },
    getStatus(status) {
      if (status === 1) {
        return 'none'
      } else if (status === 2) {
        return 'lock'
      } else if (status === 3) {
        return 'ok'
      } else if (status === 4) {
        return 'initializing'
      } else if (status === 5) {
        return 'pending'
      } else {
        return 'none'
      }
    },

    async setIsCreationNominated() {
      if (!this.activeCampaign) {
        this.isCreationNominated = false

        return
      }

      try {
        const response = await axios.get(
          `${process.env.VUE_APP_AWARD_APP_API_URL}/candidate/checknomination/${this.$route.params.id}/campaign/${this.activeCampaign.Id}`
        )

        if (response.data && response.data.data) {
          this.candidate = response.data.data

          if (this.candidate.Status !== 'Rejected' && this.candidate.Status !== 'UnNominated') {
            this.isCreationNominated = true
          } else {
            this.isCreationNominated = false
          }
        } else {
          this.isCreationNominated = false
        }
      } catch (e) {
        this.isCreationNominated = false
      }
    },
  },

  computed: {
    getLoggedInUser() {
      return this.$store.getters['getLoggedInUser']
    },
    showUnNominateModal() {
      return this.$store.state.magazineAward.showUnNominateModal
    },
    showUnNominateConfirmationModal() {
      return this.$store.state.magazineAward.showUnNominateConfirmationModal
    },
    showParticipateAwardModal() {
      return this.$store.state.magazineAward.showParticipateAwardModal
    },
    showParticipateAwardConfirmationModal() {
      return this.$store.state.magazineAward.showParticipateAwardConfirmationModal
    },
    titleHasNotBeenSet() {
      const title = this.$store.getters['getTitle']
      if (title === 'Untitled') {
        return true
      }
      return false
    },
    sortDocuments() {
      let list = []
      this.$store.getters['getDocumentsFromApi'].filter((document) => {
        if (document.type === 2 || document.type === 3) list.push(document)
      })

      return list.sort((a, b) => {
        return a.number - b.number
      })
    },
    magazine() {
      return this.$store.getters['getPagesMagazine']
    },
    getBackCover() {
      return this.$store.getters['getDocumentsFromApi'].find((document) => document.type === 4)
    },
    getFrontCover() {
      return this.$store.getters['getDocumentsFromApi'].find((document) => document.type === 1)
    },
    getIsLindaMagazine() {
      return this.$store.getters['getIsLindaMagazine']
    },
    getIsGlossyMagazine() {
      return this.$store.getters['getIsGlossyMagazine']
    },
    showAwardUI() {
      return this.$store.state.magazineAward.showAwardUI
    },
    activeCampaign() {
      return this.$store.state.magazineAward.activeCampaign
    },
    awardVotingEnded() {
      return this.$store.state.magazineAward.awardVotingEnded
    },
  },

  watch: {
    magazine: {
      handler: function (val) {
        if (val.status === 4) {
          this.disableButtons = true
        } else {
          this.disableButtons = false
        }
      },
      deep: true,
    },

    activeCampaign: {
      handler: function (val) {
        if (val) {
          this.setIsCreationNominated()
        }
      },
      immediate: true,
      deep: true,
    },
  },

  beforeCreate: function () {
    document.body.className = 'document-body'
  },
}
</script>

<style scoped>
.disable-btn {
  pointer-events: none !important;
  background-color: #f2f1eb !important;
  color: #ccc4bc !important;
  user-select: none !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2) !important;
}
</style>
