<template>
  <div
    :class="{ blueStroke: checkIfCreationSelected() }"
    class="creation"
    @mouseleave="mouseOverCreationLeave"
    @mouseover="mouseOverCreation($event)"
  >
    <div style="position: relative">
      <div v-if="checkIfInvitationPage && showAwardUI" class="hover-div"></div>

      <!-- Award Icon -->
      <div v-show="isCreationNominated && showAwardUI" class="badge-container" :style="getBgColorAwardBadge">
        <img
          v-if="
            candidate &&
            candidate.CountryRanking !== 1 &&
            candidate.CountryRanking !== 2 &&
            candidate.CountryRanking !== 3
          "
          :src="require('@/assets/award_badge_long_2024.png')"
          alt=""
          class="badge-position"
        />
        <img
          v-if="candidate && candidate.CountryRanking === 1"
          :src="require('@/assets/award_badge_long_best_2024.png')"
          alt=""
          class="badge-position"
        />
        <img
          v-if="candidate && candidate.CountryRanking === 2"
          :src="require('@/assets/award_badge_long_second_2024.png')"
          alt=""
          class="badge-position"
        />
        <img
          v-if="candidate && candidate.CountryRanking === 3"
          :src="require('@/assets/award_badge_long_third_2024.png')"
          alt=""
          class="badge-position"
        />
      </div>

      <div
        v-if="
          $store.getters['getSelectedSharedCreationRole'] !== groupRole &&
          $store.getters['getSelectedSharedCreationRole'] !== 'none'
        "
        class="creationOpacity"
        style="opacity: 70% !important"
      ></div>

      <div class="creation-status-ready" v-if="creation.status === 3 && !mouseOver">
        <div class="creation-status-ready-content">
          <i class="icon-ok"></i>
          {{ $t('can_order_creation') }}
        </div>
      </div>

      <div style="position: relative">
        <div
          v-if="
            ($store.getters['getSelectedSharedCreationRole'] === 'none' ||
              $store.getters['getSelectedSharedCreationRole'] === groupRole) &&
            ($store.getters['getSelectedSharedCreationRole'] === groupRole ||
              (mouseOver && $store.getters['getCreations'].length > 1) ||
              (mouseOver && !$store.getters['getViewMyCreations']) ||
              $store.getters['getSelectedCreations'].length > 0)
          "
          class="creation-checkbox"
          v-on:click="clickedCreationToggle()"
        ></div>

        <i v-if="checkIfCreationSelected()" class="icon-ok creation-checkbox" v-on:click="clickedCreationToggle()"></i>

        <i
          v-if="
            !$store.getters['getSidebarOpen'] &&
            ((mouseOver &&
              !checkIfCreationSelected() &&
              role === 'maker' &&
              $store.getters['getSelectedCreations'].length === 0) ||
              (mouseOver && $store.getters['getSelectedCreations'].length === 0))
          "
          class="icon-view menu-item-sub previewIcon"
          @click="preview()"
        ></i>

        <i
          v-if="
            $store.getters['getSelectedSharedCreationRole'] === 'none' &&
            ((mouseOver &&
              !checkIfCreationSelected() &&
              role === 'owner' &&
              $store.getters['getSelectedCreations'].length === 0 &&
              $store.getters['getViewMyCreations']) ||
              (mouseOver && !checkIfCreationSelected() && role !== 'maker'))
          "
          class="icon-menu menu-item-sub"
          @click="setSubmenu"
        ></i>
        <div v-if="sub_menu_creation_active" class="creation_sub_menu">
          <div
            v-if="role === 'owner' || 'chiefmaker'"
            class="creation_sub_menu_item_container"
            v-on:click="duplicateCreation(creation.id)"
          >
            <i class="icon-duplicate"></i>
            <span>{{ $t('creation.duplicate') }}</span>
          </div>

          <div
            v-if="!$store.getters['getViewMyCreations'] && role !== 'maker'"
            class="creation_sub_menu_item_container"
            v-on:click="openInviteModal(creation.id)"
          >
            <i class="icon-invite"></i>
            <span>{{ $t('creation.invite') }}</span>
          </div>

          <div
            class="creation_sub_menu_item_container"
            v-if="role !== 'maker' && role !== 'chiefmaker'"
            v-on:click="deleteCreation()"
          >
            <i class="icon-delete"></i>
            <span>{{ $t('creation.delete') }}</span>
          </div>
          <div v-if="role !== 'maker'" @click="preview" class="creation_sub_menu_item_container">
            <i class="icon-view"></i>
            <span>{{ $t('creation.preview') }}</span>
          </div>
          <div v-if="role !== 'maker'" class="creation_sub_menu_item_container" @click="order">
            <i class="icon-download"></i>
            <span>{{ $t('creation.order') }}</span>
          </div>
        </div>

        <div
          class="position-relative"
          :class="{
            hoverOn: !$store.getters['getSidebarOpen'] && $store.getters['getSelectedCreations'].length === 0,
          }"
        >
          <img :src="imgUrl" alt="creation_image" class="img" v-on:error="setDefaultImg" />

          <div v-if="creation.canvasVersion === 'linda' && imgUrl === ''">
            <CertifiedCarrier
              label-container="certified-carrier certified-carrier__overview-tile certified-carrier__overview-tile--dark"
            />
          </div>

          <div v-else-if="creation.canvasVersion === 'linda'">
            <CertifiedCarrier label-container="certified-carrier certified-carrier__overview-tile" />
          </div>
        </div>

        <Button
          v-if="
            !$store.getters['getSidebarOpen'] &&
            mouseOver &&
            !checkIfCreationSelected() &&
            $store.getters['getSelectedCreations'].length === 0
          "
          :class="{
            editSharedButton: !$store.getters['getViewMyCreations'],
          }"
          :button-text="$t('creation.button.edit')"
          button-type="primary"
          icon="edit2"
          icon-color="#fff"
          class="btnEdit z-10"
          @click.native="editCreationClicked"
        />
      </div>
    </div>

    <!-- Creation Title and Edit Buttons -->
    <div class="overview-container">
      <input
        v-if="editMode"
        @blur="saveTitle(creation)"
        @keyup.enter="saveTitle(creation)"
        class="inputCreationName input-custom"
        id="edit-creation-title"
        placeholder="Creation name"
        type="text"
        v-model="title"
      />

      <div v-else class="title-container" style="width: 100%" @click="onEditTitleBtnClick(creation.title)">
        <span v-if="checkIfInvitationPage" class="custom-btn" :style="!checkIfInvitationPage ? 'cursor: pointer' : ''">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.1562 17.3594C17.4062 17.3594 17.6094 17.4531 17.7656 17.6406C17.9219 17.7969 18 17.9844 18 18.2031C18 18.6719 17.75 18.9375 17.25 19H17.1562H1.82812C1.60938 19 1.40625 18.9219 1.21875 18.7656C1.0625 18.6094 0.984375 18.4219 0.984375 18.2031C0.984375 17.7031 1.23438 17.4219 1.73438 17.3594H1.82812H17.1562ZM10.5938 1.23438C11 0.828125 11.4062 0.828125 11.8125 1.23438L15.1875 4.51562C15.5625 4.92188 15.5625 5.3125 15.1875 5.6875L5.85938 14.6875C5.64062 14.8438 5.4375 14.9219 5.25 14.9219H1.82812C1.60938 14.9219 1.40625 14.8438 1.21875 14.6875C1.0625 14.5 0.984375 14.2969 0.984375 14.0781V10.7969C0.984375 10.5781 1.07812 10.3906 1.26562 10.2344L10.5938 1.23438ZM11.2031 2.96875L2.71875 11.1719V13.2812H4.875L13.4062 5.07812L11.2031 2.96875Z"
              fill="#999187"
            />
          </svg>
        </span>

        <p
          v-show="creation.title && creation.title.length > 24 && !editMode"
          v-b-tooltip.hover
          :title="creation.title"
          class="creation_name"
          :style="{
            padding: '0.25rem 0.5rem',
            border: '1px solid transparent',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: checkIfInvitationPage ? 'pointer' : 'auto',
          }"
        >
          {{ creation.title }}
        </p>

        <p
          v-show="creation.title && creation.title.length <= 24 && !editMode"
          class="creation_name"
          :style="{
            padding: '0.25rem 0.5rem',
            border: '1px solid transparent',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: checkIfInvitationPage ? 'pointer' : 'auto',
          }"
        >
          {{ creation.title }}
        </p>
      </div>
    </div>

    <!--TODO need to add user to creation payload -->
    <div v-if="checkIfInvitationPage">
      <div v-if="creation.collaborators && creation.collaborators.length !== 0" class="share-container">
        <div class="disableHover flex items-center">
          <v-gravatar
            v-for="(avatar, i) in creation.collaborators.slice(0, 4)"
            :key="'shared' + avatar.id + i"
            :email="avatar.email"
            class="user_img"
          />
          <span v-if="creation.collaborators && creation.collaborators.length > 4" class="count">
            + {{ creation.collaborators.length - 4 }}
          </span>
        </div>
        <Button
          style="width: auto"
          v-if="role !== 'maker'"
          :button-text="$t('creation.invite')"
          button-type="primary"
          icon="invite"
          icon-color="#fff"
          @click.native="openInviteModal(creation.id)"
        />
      </div>
    </div>

    <div
      class="absolute bottom-0 left-1/2 transform -translate-x-1/2 btn-fix space-x-2 flex justify-center items-center border-t border-wg-light-information w-full py-2.5 px-2"
      v-if="!checkIfInvitationPage"
    >
      <Button @click.native="acceptInvitation" buttonType="invitation_accept" :buttonText="$t('acccept_invitation')" />
      <Button
        @click.native="declineInvitation"
        buttonType="invitation_decline"
        :buttonText="$t('decline_invitation')"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import axios from 'axios'
import { acceptInvitation, declineInvitation } from '../domains/invitation/invitationEndpoint'
import CertifiedCarrier from './CertifiedCarrier'

export default {
  components: { Button, CertifiedCarrier },
  props: ['creation'],
  name: 'Creation',
  data() {
    return {
      mouseOver: false,
      sub_menu_creation_active: false,
      clickedCreation: false,
      clickedViaCheckbox: false,
      groupRole: this.setGroupRole(),
      role: '',
      app_status: process.env.VUE_APP_STATUS,
      imgUrl: '',
      editMode: false,
      title: '',
      isCreationNominated: false,
      candidate: null,
    }
  },

  watch: {
    editMode: function (val) {
      if (val) {
        const inputs = document.querySelectorAll('.input-custom')

        inputs.forEach((input) => {
          input.focus()
        })
      }
    },

    nominatedMagazines: {
      handler: async function (val) {
        const isMagazineNominated = val.find(
          (magazine) => magazine.ReferralId === this.creation.id && magazine.Status === 'Approved'
        )

        if (isMagazineNominated) {
          try {
            const response = await axios.get(
              `${process.env.VUE_APP_AWARD_APP_API_URL}/candidate/checknomination/${this.creation.id}`
            )

            if (response.data && response.data.data) {
              this.candidate = response.data.data

              if (this.candidate.Status !== 'Rejected' && this.candidate.Status !== 'UnNominated') {
                this.isCreationNominated = true
              } else {
                this.isCreationNominated = false
              }
            } else {
              this.isCreationNominated = false
            }
          } catch (e) {
            this.isCreationNominated = false
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },

  async created() {
    await this.createThumbnailUrl()

    if (this.checkIfInvitationPage) {
      const owner = this.creation.collaborators.find((collaborator) => collaborator.role.id === 1)

      if (owner) {
        this.getRoleById(owner.role.id)
      }
    }
  },

  methods: {
    async declineInvitation() {
      await declineInvitation(this.creation.token)
      this.$emit('refresh_invitation')
    },
    async acceptInvitation() {
      await acceptInvitation(this.creation.id, this.creation.token)
      this.$emit('refresh_invitation')
    },
    setGroupRole() {
      if (this.checkIfInvitationPage) {
        return this.creation.pivot.role_id === 1 ? 'owner' : 'chiefmaker'
      }
    },
    setDefaultImg() {
      this.creation.coverImage = '/nocover.png'
    },
    preview() {
      this.$router.push(`preview/${this.creation.id}`)
    },
    getRoleById(roleId) {
      if (this.checkIfInvitationPage) {
        if (roleId === 1) {
          this.role = 'owner'
        } else if (roleId === 2) {
          this.role = 'chiefmaker'
        } else if (roleId === 3) {
          this.role = 'maker'
        }
      }
    },
    async createThumbnailUrl() {
      await axios
        .get(
          `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${this.creation.id}/png_thumbnail/${this.creation.cover_id}.png`
        )
        .then(() => {
          const randomInt = Math.floor(Math.random() * 1000)
          this.imgUrl = `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${this.creation.id}/png_thumbnail/${this.creation.cover_id}.png?id=${randomInt}`
        })
        .catch(() => {
          this.imgUrl = '/nocover.png'
        })
    },
    openInviteModal(documentId) {
      //if(this.creation.canvasVersion === "linda" && this.$store.getters['getVisitor'] !== "NL")
      this.$store.dispatch('clearDocumentsFromApi')
      this.$store.dispatch('setCreationId', documentId)
      this.$store.dispatch('setDocuments', documentId)
      this.$store.dispatch('setShowInviteForm', true)
      this.$store.dispatch('setShowModal', true)
    },
    mouseOverCreation(event) {
      if (this.checkIfInvitationPage) {
        if (
          event.target.className !== 'creation_name' &&
          event.target.className !== 'share-container' &&
          event.target.className !== 'inviteLink' &&
          event.target.className !== 'btnInvite' &&
          event.target.className !== 'user_img' &&
          event.target.className !== 'disableHover' &&
          event.target.className !== 'btnInvite'
        ) {
          this.mouseOver = true
        } else {
          this.mouseOver = false
        }
      }
    },
    mouseOverCreationLeave() {
      if (this.checkIfInvitationPage) {
        this.mouseOver = false
        this.sub_menu_creation_active = false
      }
    },
    setSubmenu() {
      this.sub_menu_creation_active = !this.sub_menu_creation_active
    },
    clickedCreationToggle() {
      this.sub_menu_creation_active = false
      if (this.checkIfCreationSelected()) {
        this.$store.dispatch('removeSelectedCreation', this.creation)
      } else {
        this.$store.dispatch('setSelectedCreations', this.creation)
        this.$store.dispatch('setSelectedSharedCreationRole', this.groupRole)
      }

      if (this.$store.getters['getSidebarOpen'] === false) {
        if (!this.sub_menu_creation_active) {
          this.$store.dispatch('setSidebar', true)
          this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
        }
      } else {
        if (this.$store.getters['getSelectedCreations'].length === 0) {
          this.$store.dispatch('setSidebar', false)
          this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
          this.$store.dispatch('setSelectedSharedCreationRole', 'none')
        }
      }
    },
    order() {
      this.$store.dispatch('setIsLinda', this.creation.canvasVersion === 'linda')
      this.$store.dispatch('setIsGlossy', this.creation.canvasVersion === 'glossy')
      this.$router.push('/order-checkout/' + this.creation.id)
    },
    checkIfCreationSelected() {
      const index = this.$store.getters['getSelectedCreations'].indexOf(this.creation)
      return index !== undefined && index !== -1
    },
    editCreationClicked() {
      this.$store.dispatch('setViewMyCreations', true)
      this.$router.push('/document/' + this.creation.id)
    },
    openInvitePanel() {
      this.$store.dispatch('setSidebarType', 'invite_sidebar')
      this.$store.dispatch('setSidebar', true)
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    deleteCreation() {
      const creations = [this.creation]
      this.$store.dispatch('setCreationsToDelete', creations)

      this.$store.dispatch('setShowDeleteModal', true)
    },
    async duplicateCreation(creationId) {
      // await this.$store.dispatch('setIsLoadingCreationOverview', true)
      await this.$store.dispatch('setDuplicationCreationState', true)
      setTimeout(() => {
        this.$store.dispatch('setDuplicationCreationState', false)
      }, 10000)

      await this.$store.dispatch('duplicateCreation', creationId)

      // await this.$store.dispatch("getCreations")
      // await this.$store.dispatch('setIsLoadingCreationOverview', false)
    },

    async saveTitle(creation) {
      if (this.title && this.title !== creation.title) {
        const payload = {
          id: creation.id,
          data: {
            title: this.title,
          },
        }
        await this.$store.dispatch('updateCreationTitle', payload)

        this.$emit('reload-data')
      }

      this.editMode = false
    },

    async onEditTitleBtnClick(title) {
      if (this.checkIfInvitationPage) {
        this.title = title
        this.editMode = true

        await this.$nextTick()

        const input = document.getElementById('edit-creation-title')

        if (input) {
          input.focus()
          input.setSelectionRange(0, input.value.length)
        }
      }
    },
  },
  computed: {
    checkIfInvitationPage() {
      return this.$route.name !== 'invitation-overview'
    },
    nominatedMagazines() {
      return this.$store.state.magazineAward.nominatedMagazines
    },

    showAwardUI() {
      return this.$store.state.magazineAward.showAwardUI
    },

    activeCampaign() {
      return this.$store.state.magazineAward.activeCampaign
    },
    getBgColorAwardBadge() {
      if (this.candidate && this.candidate.CountryRanking) {
        switch (this.candidate.CountryRanking) {
          case 1:
            return 'background: linear-gradient(90deg, #C49817 0.14%, #D0A751 46.07%, #AE8E25 99.99%)'
          case 2:
            return 'background: linear-gradient(90deg, #676767 0.14%, #AEABA5 46.07%, #686868 99.99%)'
          case 3:
            return 'background: linear-gradient(90deg, #863406 0.14%, #E6772C 50.82%, #843505 99.99%)'
          default:
            return 'background: linear-gradient(90deg, #000000 0.14%, #1F1010 46.07%, #000000 99.99%)'
        }
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped>
.input-custom {
  border: 1px solid black !important;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.custom-btn {
  /* margin-bottom: 0.5rem; */
  display: none;
  margin-right: 0rem;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.title-container:hover span {
  display: inline-block;
}

.overview-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
}

.badge-container {
  position: absolute;
  margin-right: 0.5rem;
  bottom: 0px;
  left: 0px;
  height: 33px;
  width: 100%;
  z-index: 1;
}

.badge-position {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.creation:hover .hover-div {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.btn-fix button {
  position: static !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
}
</style>
