<template>
  <div class="order-item">
    <div class="order-overview-detail" v-if="!$store.getters['getIsLoadingOrderItem']">
      <img :src="imgUrl" @error="setAltImg" alt="Creation cover" />

      <div
        v-if="this.$route.query.confirmation !== 'true' && this.$route.query.confirmation !== 'false'"
        class="order-item-info-container"
      >
        <h4>{{ $t('checkout.order.summary') }}</h4>

        <p
          v-if="
            !this.$store.getters['getCanOrderPFDOnly'] &&
            !this.$store.getters['getCanOrderExtrasOnly'] &&
            canShowCreation
          "
          style="margin-bottom: 5px"
        >
          {{ getMagazineAndPrintType }}
        </p>

        <div v-if="canShowCreation" class="d-flex order-item-item justify-content-between">
          <p
            v-if="!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly']"
            style="margin-left: 32px; font-size: 14px"
          >
            {{ calculator.amount }}
            {{ calculator.amount === 1 ? $t('checkout.copy.singular') : $t('checkout.copies') }}
            <span class="pricePerMagazine"
              >({{ calculator.pricePerMagazineWithoutDiscountWithoutVATFormatted }} {{ $t('checkout.copy') }})</span
            >
          </p>
          <p
            v-if="!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly']"
            class="priceMagazine"
          >
            {{ calculator.magazinesPriceWithoutDiscountWithoutVATFormatted }}
          </p>
        </div>

        <div
          v-if="
            calculator.orderextras.filter((o) => o.name.includes('pdf')).length > 0 &&
            !this.$store.getters['getIsEmptyOrder']
          "
        >
          <p style="margin-bottom: 5px">PDF</p>
          <div
            v-for="p in calculator.orderextras.filter((o) => o.name.includes('pdf'))"
            :key="'pdf' + p.id"
            class="d-flex order-item-item justify-content-between"
          >
            <p style="margin-left: 32px; font-size: 14px">{{ getExtraName(p.name) }}</p>
            <p style="font-size: 14px">€{{ (p.priceWithoutVAT / 100).toFixed(2).toString().replace('.', ',') }}</p>
          </div>
        </div>

        <div
          v-if="
            calculator.orderextras.filter((o) => o.name.includes('poster')).length > 0 &&
            !this.$store.getters['getIsEmptyOrder']
          "
        >
          <p style="margin-bottom: 5px">Poster</p>
          <div
            v-for="p in calculator.orderextras.filter((o) => o.name.includes('poster'))"
            :key="'poster' + p.id"
            class="d-flex order-item-item justify-content-between"
          >
            <p style="margin-left: 32px; font-size: 14px">{{ getExtraName(p.name) }}</p>
            <p style="font-size: 14px">€{{ (p.priceWithoutVAT / 100).toFixed(2).toString().replace('.', ',') }}</p>
          </div>
        </div>

        <div class="d-flex justify-content-between shipping">
          <p class="shipping">{{ $t('checkout.delivery') }}</p>
          <p
            v-if="
              !this.$store.getters['getCanOrderPFDOnly'] &&
              !this.$store.getters['getCanOrderExtrasOnly'] &&
              calculator.orderextrasShippingWithVAT === 0
            "
            class="shippingPrice"
          >
            {{ calculator.shippingPriceWithoutVATFormatted }}
          </p>
          <p v-else-if="calculator.orderextrasShippingWithVAT !== 0">{{ getShippingPricePoster }}</p>
          <p v-else class="shippingPrice">{{ $t('checkout.free') }}</p>
        </div>

        <p v-if="discountPerc && !this.$store.getters['getIsEmptyOrder']" class="discount">
          <span>{{ discountPerc }}% discount ({{ discountPerCopy }} per copy)</span>
          <span>- {{ totalDiscount }}</span>
        </p>

        <hr />

        <div class="d-flex justify-content-between">
          <p>
            <strong class="total">{{ $t('checkout.total') }}</strong>
            <span class="totalPrice"
              >({{ !this.$store.getters['getIsEmptyOrder'] ? calculator.totalPriceVATFormatted : '€0,00' }}
              {{ $t('checkout.taxes.included') }})</span
            >
          </p>
          <h2>{{ !this.$store.getters['getIsEmptyOrder'] ? calculator.totalPriceWithVATFormatted : '€0,00' }}</h2>
        </div>
      </div>

      <div v-else class="order-item-info-container">
        <h4>{{ $t('checkout.order.summary') }}</h4>

        <p
          v-if="
            (!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly']) ||
            this.$store.getters['getVolume'] === 0
          "
          style="margin-bottom: 5px"
        >
          {{ getMagazineAndPrintType }}
        </p>

        <div class="d-flex order-item-item justify-content-between">
          <p
            v-if="
              (!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly']) ||
              this.$store.getters['getVolume'] === 0
            "
            style="margin-left: 32px; font-size: 14px"
          >
            {{ getAmount }} {{ parseInt(getAmount) === 1 ? $t('checkout.copy.singular') : $t('checkout.copies') }}
            <span class="pricePerMagazine">(€{{ getPricePerCopy }} {{ $t('checkout.copy') }})</span>
          </p>
          <p
            v-if="!this.$store.getters['getCanOrderPFDOnly'] && !this.$store.getters['getCanOrderExtrasOnly']"
            class="priceMagazine"
          >
            {{ getMagazinesPriceWithoutVat }}
          </p>
        </div>

        <div v-if="getOrderExtras.filter((o) => o.name.includes('pdf')).length > 0">
          <p style="margin-bottom: 5px">PDF</p>
          <div
            v-for="p in getOrderExtras.filter((o) => o.name.includes('pdf'))"
            :key="'pdf' + p.id"
            class="d-flex order-item-item justify-content-between"
          >
            <p style="margin-left: 32px; font-size: 14px">{{ getExtraName(p.name) }}</p>
            <p style="font-size: 14px">€{{ (p.priceWithoutVAT / 100).toFixed(2).toString().replace('.', ',') }}</p>
          </div>
        </div>

        <div v-if="getOrderExtras.filter((o) => o.name.includes('poster')).length > 0">
          <p style="margin-bottom: 5px">Poster</p>
          <div
            v-for="p in getOrderExtras.filter((o) => o.name.includes('poster'))"
            :key="'poster' + p.id"
            class="d-flex order-item-item justify-content-between"
          >
            <p style="margin-left: 32px; font-size: 14px">{{ getExtraName(p.name) }}</p>
            <p style="font-size: 14px">€{{ (p.priceWithoutVAT / 100).toFixed(2).toString().replace('.', ',') }}</p>
          </div>
        </div>

        <div class="d-flex justify-content-between shipping">
          <p>{{ $t('checkout.delivery') }}</p>
          <p class="shippingPrice">€{{ getShippingPriceWithoutVat }}</p>
        </div>
        <p v-if="discountPerc && !this.$store.getters['getIsEmptyOrder']" class="discount">
          <span>{{ discountPerc }}% discount ({{ discountPerCopy }} per copy)</span>
          <span>- {{ totalDiscount }}</span>
        </p>

        <hr />

        <div class="d-flex justify-content-between">
          <p>
            <strong class="total">{{ $t('checkout.total') }}</strong>
            <span class="totalPrice">(€{{ getTaxes }} {{ $t('checkout.taxes.included') }})</span>
          </p>
          <h2>{{ getTotalPriceWithVat }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderItem',
  props: ['calculator', 'magazine'],
  data() {
    return {
      imgUrl: `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${this.$route.params.id}/png_thumbnail/${this.magazine.cover_id}.png`,
    }
  },
  methods: {
    setAltImg(event) {
      event.target.src = '/nocover.png'
    },
    getExtraName(name) {
      if (name === 'pdf_72dpi_magazine') {
        return this.$t('checkout.low.quality')
      } else if (name === 'pdf_300dpi_cover') {
        return this.$t('checkout.high.quality')
      } else if (name === 'poster_a0') {
        return 'A0'
      } else if (name === 'poster_a1') {
        return 'A1'
      } else if (name === 'poster_a2') {
        return 'A2'
      } else if (name === 'poster_a3') {
        return 'A3'
      } else if (name === 'poster_a3_plus') {
        return 'A3++'
      } else if (name === 'poster_a4') {
        return 'A4'
      }
    },
    getPrintOptionFinnish(id) {
      switch (id) {
        case 1:
          return this.$t('checkout.matt.text')
        case 2:
          return this.$t('checkout.glossy.text')
        case 3:
          return this.$t('checkout.glossy.text')
        case 5:
          return this.$t('checkout.matt.text')
      }
    },
    getPrintOptionType(id) {
      switch (id) {
        case 1:
          return this.$t('checkout.stapled.text')
        case 2:
          return this.$t('checkout.glued.text')
        case 3:
          return this.$t('checkout.stapled.text')
      }
    },
    getSelectPrintOption(name) {
      if (name === 'stapled') {
        return this.$t('checkout.stapled')
      } else if (name === 'glossy') {
        return this.$t('checkout.glossy')
      } else if (name === 'glossycover') {
        return this.$t('checkout.glossycover')
      } else if (name === 'book') {
        return this.$t('checkout.book')
      } else {
        return ''
      }
    },
    getMagazineFinish(id, successfulOrder = false) {
      let finish = id

      if (!successfulOrder) {
        finish =
          this.calculator.printingtype_id !== undefined
            ? this.calculator.printingtype_id
            : this.$store.getters['getPrintOptions'][0].id
      }

      const transRef = this.getPrintOptionFinnish(finish)
      return transRef === undefined ? null : transRef
    },
    getMagazineCoverType(id, successfulOrder = false) {
      let fullType = id

      if (!successfulOrder) {
        fullType =
          this.calculator.printingtype_id !== undefined || null
            ? this.calculator.printingtype_id
            : this.$store.getters['getPrintOptions'][0].id
      }

      const transRef = this.getPrintOptionType(fullType)
      return transRef === undefined ? '' : transRef
    },
  },
  computed: {
    discountPerc() {
      return (this.calculator && this.calculator.discountPercentage) || ''
    },
    discountPerCopy() {
      return (this.calculator && this.calculator.pricePerMagazineWithoutVATFormatted) || ''
    },
    totalDiscount() {
      return (this.calculator && this.calculator.discountWithoutVATFormatted) || ''
    },
    getMagazineType() {
      const complete = this.$store.getters['getSuccessOrder'].magazine
      return complete === undefined
        ? this.magazine.canvasVersion
        : this.$store.getters['getSuccessOrder'].magazine.canvasVersion
    },
    getMagazineAndPrintType() {
      const canvasVersion = this.getMagazineType
      const magazineType =
        canvasVersion === 'default'
          ? this.$t('checkout.magazine.text')
          : canvasVersion === 'linda'
          ? 'LINDA. ' + this.$t('checkout.magazine.text')
          : this.$t('checkout.magazine.text')
      let finish = ''
      let coverType = ''
      let proof = ''

      if (this.$store.getters['getSuccessOrder'].magazine) {
        const printTypeId = this.$store.getters['getSuccessOrder'].printingtype_id
        proof = this.$store.getters['getSuccessOrder'].proof
          ? this.$t('checkout.sample.copy')
          : this.$t('checkout.final.creation')

        finish = this.getMagazineFinish(printTypeId, true)
        coverType = this.getMagazineCoverType(printTypeId, true)
      } else {
        finish = this.getMagazineFinish()
        coverType = this.getMagazineCoverType()
        proof =
          this.$store.getters['getProof'] === 'true'
            ? this.$t('checkout.sample.copy')
            : this.$t('checkout.final.creation')
      }

      if (this.$store.getters['getPrint'] === 4 && finish === null) {
        return this.$t('checkout.book') + ' ' + ' (' + proof + ')'
      } else {
        return (
          coverType +
          ' ' +
          magazineType +
          ' ' +
          this.$t('with.a') +
          ' ' +
          finish +
          ' ' +
          this.$t('checkout.sample.finish') +
          ' (' +
          proof +
          ')'
        )
      }
    },
    canShowCreation() {
      return (
        this.calculator.amount > 0 &&
        !this.$store.getters['getIsEmptyOrder'] &&
        !this.$store.getters['getIsInvalidVolume']
      )
    },
    getAmount() {
      return this.$store.getters['getSuccessOrder'].amount
    },
    getPricePerCopy() {
      return (
        this.$store.getters['getSuccessOrder'].magazinesPriceWithoutVAT /
        this.$store.getters['getSuccessOrder'].amount /
        100
      )
        .toFixed(2)
        .toString()
        .replace('.', ',')
    },
    getMagazinesPriceWithoutVat() {
      return (
        '€' +
        (this.$store.getters['getSuccessOrder'].magazinesPriceWithoutVAT / 100).toFixed(2).toString().replace('.', ',')
      )
    },
    getOrderExtras() {
      return this.$store.getters['getSuccessOrder'].orderextras
    },
    getShippingPriceWithoutVat() {
      const price = (this.$store.getters['getSuccessOrder'].shippingPriceWithoutVAT / 100)
        .toFixed(2)
        .toString()
        .replace('.', ',')
      return price === 0 ? '0,00' : price
    },
    getTaxes() {
      return (
        (this.$store.getters['getSuccessOrder'].totalLowVAT + this.$store.getters['getSuccessOrder'].totalHighVAT) /
        100
      )
        .toFixed(2)
        .toString()
        .replace('.', ',')
    },
    getTotalPriceWithVat() {
      return (
        '€' + (this.$store.getters['getSuccessOrder'].totalPriceWithVAT / 100).toFixed(2).toString().replace('.', ',')
      )
    },
    getShippingPricePoster() {
      return `€ ${((this.calculator.orderextrasShippingWithoutVAT + this.calculator.shippingPriceWithoutVAT) / 100)
        .toFixed(2)
        .toString()
        .replace('.', ',')}`
    },
  },
}
</script>

<style scoped>
/* mt-1 text-sm text-[#1FA549] font-bold flex justify-between items-center space-x-4 */

.discount {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #1fa549;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
</style>
