<template>
  <div class="wrapper overflow-hidden relative pt-10" i id="pageflip">
    <p
      class="absolute top-0 left-1/2 text-white transform -translate-x-1/2 flex justify-center items-center space-x-2 py-2 px-2 rounded-md mt-6 text-sm"
    >
      <span>
        <i class="text-white text-xl icon-info-outline transform translate-y-1 inline-block"></i>
      </span>
      <span>
        {{ $t('preview.page.warning') }}
      </span>
    </p>

    <div class="content" v-if="!isLoading">
      <div class="flexCenter">
        <div class="copy">
          <div class="backToOverview">
            <a :href="documentUrl">
              <Button
                :button-text="$t('editor.imagemanager.button_back_to_overview')"
                button-type="primary"
                icon="chevron-left"
              />
            </a>
          </div>

          <!-- <span class="btn-container">
            <input
              type="number"
              class="go-to-input"
              v-model="pageNumber"
              @blur="onGoToPage"
              @keyup.enter="(e) => e.target.blur()"
            />
          </span> -->

          <div class="copyLink" :class="{ hiddenLink: !showShareLink }">
            <SuccessShared v-if="showSuccess" />
            <div>
              <Copy />
            </div>
            <span class="underline" v-on:click="copyShareLink()">{{ $t('pageflip.share') }}</span>
            <span class="noUnderline">{{ $t('pageflip.shareTwo') }}</span>
          </div>
        </div>

        <flipbook
          class="flipbook"
          :pages="pages"
          v-slot="flipbook"
          :flip-duration="flipDuration"
          :loadingImage="`${this.baseUrl}/blank.png?id=${this.imageId}`"
          @flip-left-end="onLeftFlipEnd"
          @flip-right-end="onRightFlipEnd"
        >
          <span
            class="flex justify-center items-center space-x-2 absolute top-0 -mt-12 left-1/2 transform -translate-x-1/2 z-10"
          >
            <span class="inline-block relative">
              <input
                id="go-to-page"
                type="text"
                class="go-to-input"
                v-model="pageNumber"
                :disabled="isPageFlipping"
                @change="() => onGoToPage(flipbook)"
                @focus="showPagesDropdown = true"
                @blur="onInputBlur"
                @keyup.enter="() => onGoToPage(flipbook)"
              />

              <ul
                v-if="showPagesDropdown"
                id="pages-dropdown"
                class="absolute top-full left-0 bg-white min-w-full rounded-b-md overflow-y-auto z-10 shadow-md custom-scrollbar"
                style="max-height: 300px"
              >
                <li v-for="page in getPages" :key="page">
                  <button
                    class="px-2 py-1 border-b text-center inline-block w-full h-full hover:bg-gray-100"
                    @click="
                      () => {
                        pageNumber = page
                        onGoToPage(flipbook)
                      }
                    "
                  >
                    {{ page }}
                  </button>
                </li>
              </ul>
            </span>

            <span class="text-white"> of {{ flipbook.numPages }} </span>
          </span>

          <div class="flip-left">
            <i @click="!isPageFlipping && flipbook.flipLeft" class="icon-chevron-left"></i>
          </div>
          <div class="flip-right">
            <i @click="!isPageFlipping && flipbook.flipRight" class="icon-chevron-left"></i>
          </div>
        </flipbook>
      </div>
    </div>
    <div v-else class="center-loading">
      <img style="height: 50px" alt="loading" src="/loading.gif" />
    </div>
  </div>
</template>

<script>
import Flipbook from 'flipbook-vue/dist/vue2/flipbook'
import Copy from '../../components/viewteam/Copy'
import SuccessShared from '../../components/SuccessShared'

import Button from '../../components/Button'

export default {
  name: 'Preview',
  components: { Copy, SuccessShared, Button, Flipbook },
  data() {
    return {
      pageNumber: 1,
      shareLink: process.env.VUE_APP_STATUS === 'staging' ? 'https://jilst.jilster.dev/' : 'jil.st/',
      showSuccess: false,
      showNext: true,
      showPrevious: false,
      isLoading: false,
      documentUrl: '/document/' + this.$route.params.id,
      zoom: 0,
      pageFlip: null,
      pages: [],
      imageId: 0,
      baseUrl: process.env.VUE_APP_BASE_URL,
      flipDuration: 1000,
      isPageFlipping: false,
      showPagesDropdown: false,
    }
  },

  computed: {
    showShareLink() {
      return this.$store.getters['getIsShared'] && this.$store.getters['getDocumentRole'] !== 3
    },

    getPages() {
      // The array from pages.length - 1. It should have first page as 1 and last page as pages.length. All pages in between should be even numbers.
      const pages = this.pages.filter((page) => page !== null)

      if (pages.length === 0) return []

      const array = Array.from({ length: pages.length - 1 }, (_, i) => i + 1)
      const evenArray = array.filter((page) => page % 2 === 0)
      evenArray.unshift(1)
      evenArray.push(pages.length)

      return evenArray
    },
  },
  methods: {
    async onGoToPage(flipb) {
      this.isPageFlipping = true
      this.showPagesDropdown = false

      try {
        // If this.pageNumber is not a number, parseInt will return NaN. Then this.pageNumber will be set to 1.
        if (isNaN(parseInt(this.pageNumber))) {
          this.pageNumber = 1
        }

        this.pageNumber = parseInt(this.pageNumber)

        if (this.pageNumber < 1) {
          this.pageNumber = 1
        } else if (this.pageNumber > flipb.numPages) {
          this.pageNumber = flipb.numPages
        }
      } catch (e) {
        this.isPageFlipping = false
        return
      }

      if (this.pageNumber > flipb.page && this.pageNumber <= flipb.numPages) {
        // flipb.flipRight() until this.pageNumber
        this.pageNumber = this.pageNumber % 2 === 0 ? this.pageNumber : this.pageNumber - 1

        const numOfFlip = (this.pageNumber - flipb.page) / 2

        for (let i = 0; i < numOfFlip; i++) {
          this.flipDuration = 500
          await this.awaitThePageflip(flipb, 'right', i === 0 ? 0 : 550)
        }

        this.flipDuration = 1000

        this.isPageFlipping = false
      } else if (this.pageNumber < flipb.page && this.pageNumber >= 1) {
        // flipb.flipLeft() until this.pageNumber
        this.pageNumber = this.pageNumber % 2 === 0 ? this.pageNumber : this.pageNumber !== 1 ? this.pageNumber - 1 : 1

        const numOfFlip = (flipb.page - this.pageNumber) / 2

        for (let i = 0; i < numOfFlip; i++) {
          this.flipDuration = 500
          await this.awaitThePageflip(flipb, 'left', i === 0 ? 0 : 550)
        }

        this.flipDuration = 1000

        this.isPageFlipping = false
      } else {
        this.isPageFlipping = false
        return
      }
    },

    awaitThePageflip(flipb, direction, interval) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (flipb) {
            if (direction === 'left') {
              flipb.canFlipLeft && flipb.flipLeft()
            } else {
              flipb.canFlipRight && flipb.flipRight()
            }
            resolve(true)
          }
        }, interval)
      })
    },

    copyShareLink() {
      const el = document.createElement('textarea')
      el.value = this.shareLink + this.$route.params.id
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.showSuccess = true
      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    },

    async getDocumentImages() {
      await this.$store.dispatch('setDocumentsPreview', this.$route.params.id)
      let that = this

      const documents = await this.$store.getters['getDocuments']
      this.pages.push(null)
      for (let i = 0; documents.length > i; i++) {
        const randomInt = Math.floor(Math.random() * 1000)
        // setInterval(function () {
        that.imageId = Math.floor(Math.random() * 1000)
        // }, 0)

        const result = `https://s3.eu-west-1.amazonaws.com/php.jilster.nl/${process.env.VUE_APP_STATUS}_magazinepages/${documents[i].magazine_id}/png_fullsize/${documents[i].id}.png?id=${randomInt}`

        const http = new XMLHttpRequest()

        http.open('HEAD', result, false)
        http.send()

        if (http.status === 200) {
          const image = new Image()
          image.src = result

          this.pages.push(result)
        } else {
          this.pages.push(`${this.baseUrl}/blank.png?id=${this.imageId}`)
        }
      }

      this.isLoading = false

      // if (!this.isLoading) {

      //   const sortedDocuments = documents.sort((a, b) => {
      //     return a.number - b.number;
      //   });

      //   let list = []
      //   for (let i = 0; i < sortedDocuments.length; i++) {
      //     list.push(sortedDocuments[i].imageUrl);
      //   }
      //   list.unshift('/empty-page-flip.png')
      //   list.push('/empty-page-flip.png')

      //   return list
      // } else {
      //   return [];
      // }
    },

    onLeftFlipEnd(page) {
      if (this.isPageFlipping) {
        return
      }
      // Page number can be even number or 1
      if (page === 1) {
        this.pageNumber = 1
      } else {
        this.pageNumber = page % 2 === 0 ? page : page - 1
      }
    },

    onRightFlipEnd(page) {
      if (this.isPageFlipping) {
        return
      }

      // Page number can be even number or 1
      if (page === 1) {
        this.pageNumber = 1
      } else {
        this.pageNumber = page % 2 === 0 ? page : page - 1
      }
    },

    onInputBlur(e) {
      const that = this
      const input = e.target

      document.addEventListener('click', (_e) => {
        if (input !== _e.target) {
          that.closeDropdown(_e, that)
        }
      })
    },

    closeDropdown(e, that) {
      const dropdown = document.getElementById('pages-dropdown')

      if (dropdown && !dropdown.contains(e.target)) {
        that.showPagesDropdown = false
      }

      document.removeEventListener('click', that.closeDropdown)
    },
  },
  async created() {
    this.isLoading = true
    await this.getDocumentImages()
  },
  mounted() {
    document.body.style.height = '100vh'
    document.body.style.backgroundColor = '#2E2A26'
    document.getElementById('app').style.height = '100vh'
    document.getElementById('app').style.backgroundColor = '#2E2A26'
    document.getElementById('jilster_navbar').style.display = 'none'

    // this.getDocumentImages().then(result => {
    //   const parentElement = document.getElementById('page-flip-container')
    //   this.pageFlip = new PageFlip(parentElement, {
    //     width: 461, // base page width
    //     height: 600, // base page height,
    //   });
    //
    //   // this.pageFlip.loadFromImages(result);
    //
    //   this.pageFlip.on('flip', (e) => {
    //     const current = e.data
    //     if (current === (this.getDocumentImages.length - 2)) {
    //       this.showPrevious = true
    //       this.showNext = false
    //     } else if (current > 0) {
    //       this.showPrevious = true
    //       this.showNext = true
    //     } else if (current === 0) {
    //       this.showPrevious = false
    //       this.showNext = true
    //     }
    //   });
    // })

    const handleWheel = function (e) {
      if (e.ctrlKey || e.metaKey) e.preventDefault()
    }
    window.addEventListener('wheel', handleWheel, { passive: false })

    window.addEventListener(
      'keydown',
      function (e) {
        if (
          (e.ctrlKey || e.metaKey) &&
          (e.which === 61 ||
            e.which === 107 ||
            e.which === 173 ||
            e.which === 109 ||
            e.which === 187 ||
            e.which === 189)
        ) {
          e.preventDefault()
        }
      },
      false
    )
  },
}
</script>

<style scoped>
.flip-left i {
  position: absolute;
  font-size: 40px;
  top: 40%;
}

.flipbook {
  margin-top: 10px;
  position: relative;
  width: 90vw;
  height: 90vh;
}

.flip-right i {
  position: absolute;
  font-size: 40px;
  top: 40%;
  right: 0;
  transform: scaleX(-1);
}

.flipbook-container img {
  width: 461px !important;
  height: 600px !important;
}

.page {
  background: white;
}

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backToOverview a {
  text-decoration: none;
}

i {
  color: #999187;
  cursor: pointer;
}

.flip-right {
}

.content {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: unset;
}

.flexCenter {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-height: 800px) {
  .successShared {
    left: -150px !important;
    bottom: 0px !important;
  }
}

@media screen and (max-width: 1413px) {
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: unset;
  }

  .flexCenter {
    width: 100%;
  }
}

.copy {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
}

.copyLink {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
}

.copyLink path {
  fill: #fff;
}

.hiddenLink {
  visibility: hidden;
}

.copyLink .successShared {
  border-radius: 8px;
  bottom: 100%;
  left: 20px;
}

.copy svg {
  height: 20px;
  width: 20px;
  margin-right: 4px;
}

.copy .underline {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.copy .noUnderline {
  color: #fff;
  font-weight: normal;
  text-decoration: none;
  margin-left: 5px;
}

.zoomButtons i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.zoomActions p {
  margin: 0 16px;
}

.go-to-input {
  width: 60px;
  height: 30px;
  border: 1px solid #999187;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 18px;
  text-align: center;
  outline: none;
  position: relative;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #999187 transparent;
}
</style>
